import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, MenuItemLink, useGetIdentity } from 'react-admin';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import ArticleIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import ImageIcon from '@material-ui/icons/Image';
import CityIcon from '@material-ui/icons/LocationCity';
import DepartmentIcon from '@material-ui/icons/LocationCity';
import FFFIcon from '@material-ui/icons/LocationCity';
import DomainIcon from '@material-ui/icons/SettingsApplications';
import DeviceIcon from '@material-ui/icons/SettingsCell';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import PageIcon from '@material-ui/icons/MenuBook';
import RegionIcon from '@material-ui/icons/LocationCity';
import CountryIcon from '@material-ui/icons/LocationCity';
import CategoryIcon from '@material-ui/icons/Bookmark';
import UserIcon from '@material-ui/icons/People';
import RoleIcon from '@material-ui/icons/Gavel';
import TownIcon from '@material-ui/icons/LocationCity';
import SchoolIcon from '@material-ui/icons/School';
import QrcodeIcon from '@material-ui/icons/School';
import VersionIcon from '@material-ui/icons/SettingsApplications';
import RootIcon from '@material-ui/icons/Stars';

type MenuName =
  | 'menuAuthenticated'
  | 'menuAdmin'
  | 'menuResource'
  | 'menuLocalization';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const [state, setState] = useState({
    menuAuthenticated: true,
    menuAdmin: false,
    menuResource: false,
    menuLocalization: true,
  });

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <>
      {(identity?.user.isAuthenticated || identity?.user.isAdmin) && (
        <>
          {!identity?.user?.domain?.configuration?.disableArticle && (
            <MenuItemLink
              to={`/article`}
              primaryText={translate(`articles`, {
                smart_count: 2,
              })}
              leftIcon={<ArticleIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {!identity?.user?.domain?.configuration?.disableEvent && (
            <MenuItemLink
              to={`/event`}
              primaryText={translate(`events`, {
                smart_count: 2,
              })}
              leftIcon={<EventIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {!identity?.user?.domain?.configuration?.disableNotification && (
            <MenuItemLink
              to={`/notification`}
              primaryText={translate(`notifications`, {
                smart_count: 2,
              })}
              leftIcon={<NotificationIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {!identity?.user?.domain?.configuration?.disablePage && (
            <MenuItemLink
              to={`/page`}
              primaryText={translate(`pages`, {
                smart_count: 2,
              })}
              leftIcon={<PageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {identity?.user.isAdmin && (
            <MenuItemLink
              to={`/image`}
              primaryText={translate(`images`, {
                smart_count: 2,
              })}
              leftIcon={<ImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {(!identity?.user.category || identity?.user.isAdmin) && (
            <>
              <MenuItemLink
                to={`/domain`}
                primaryText={translate(`domain_admin`, {
                  smart_count: 2,
                })}
                leftIcon={<DomainIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </>
          )}

          {(!identity?.user.category || identity?.user.isAdmin) &&
            !identity?.user?.domain?.configuration?.disableCategory && (
              <MenuItemLink
                to={`/category`}
                primaryText={translate(`categories_admin`, {
                  smart_count: 2,
                })}
                leftIcon={<CategoryIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            )}

          {!identity?.user?.domain?.configuration?.disableUser && (
            <MenuItemLink
              to={`/user`}
              primaryText={translate(`users_admin`, {
                smart_count: 2,
              })}
              leftIcon={<UserIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </>
      )}

      {!identity?.user?.domain?.configuration?.disableDevice && (
        <MenuItemLink
          to={`/device`}
          primaryText={translate(`devices`, {
            smart_count: 2,
          })}
          leftIcon={<DeviceIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {identity?.user?.isAdmin && (
        <>
          <SubMenu
            handleToggle={() => handleToggle('menuAdmin')}
            isOpen={state.menuAdmin}
            sidebarIsOpen={open}
            name="admin"
            icon={<RootIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`role`}
              primaryText={translate(`roles`, {
                smart_count: 2,
              })}
              leftIcon={<RoleIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`qrcode`}
              primaryText={translate(`qrcodes`, {
                smart_count: 2,
              })}
              leftIcon={<QrcodeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`version`}
              primaryText={translate(`versions`, {
                smart_count: 2,
              })}
              leftIcon={<VersionIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle('menuResource')}
            isOpen={state.menuResource}
            sidebarIsOpen={open}
            name="resources"
            icon={<TownIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/school`}
              primaryText={translate(`schools`, {
                smart_count: 2,
              })}
              leftIcon={<SchoolIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/fff`}
              primaryText={translate(`fff`, {
                smart_count: 2,
              })}
              leftIcon={<FFFIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/town`}
              primaryText={translate(`towns`, {
                smart_count: 2,
              })}
              leftIcon={<TownIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle('menuLocalization')}
            isOpen={state.menuLocalization}
            sidebarIsOpen={open}
            name="localizations"
            icon={<TownIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/country`}
              primaryText={translate(`countries`, {
                smart_count: 2,
              })}
              leftIcon={<CountryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/region`}
              primaryText={translate(`regions`, {
                smart_count: 2,
              })}
              leftIcon={<RegionIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/department`}
              primaryText={translate(`departments`, {
                smart_count: 2,
              })}
              leftIcon={<DepartmentIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/city`}
              primaryText={translate(`cities`, {
                smart_count: 2,
              })}
              leftIcon={<CityIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
    </>
  );
};

export default Menu;
