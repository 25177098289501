import React from 'react';
import {
  useTranslate,
  TextInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        source="uuid"
        label={translate('uuid')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="name"
        label={translate('name')}
        helperText={false}
        fullWidth
      />
      <ReferenceInput
        source="country"
        reference="country"
        label={translate('country')}
        perPage={-1}
        allowEmpty={true}
        style={{ width: 250 }}
        helperText={false}
      >
        <SelectInput optionText="name" helperText={false} />
      </ReferenceInput>
      <ReferenceInput
        source="region"
        reference="region"
        label={translate('region')}
        perPage={-1}
        allowEmpty={true}
        style={{ width: 250 }}
        helperText={false}
      >
        <SelectInput optionText="name" helperText={false} />
      </ReferenceInput>
    </>
  );
};

export default MainTab;
