import React from 'react';
import { useTranslate, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {},
  select: {},
});

interface Props {
  label?: string;
  source?: string;
  publishDate?: boolean;
  publishDateTime?: boolean;
  mode?: 'create' | 'edit';
  limited?: boolean;
}

const ImageOrientationInput = (props: Props) => {
  const translate = useTranslate();
  let classes = useStyles();

  const imageOrientationEnum = [
    { id: 'portrait', name: translate('portrait') },
    { id: 'landscape', name: translate('landscape') },
    { id: 'square', name: translate('square') },
    { id: 'none', name: translate('none') },
  ];

  return (
    <SelectInput
      label={props.label || translate('configuration.imageOrientation')}
      source={props.source || 'configuration.imageOrientation'}
      choices={imageOrientationEnum}
      allowEmpty={true}
      className={classes.select}
      style={{ width: 250 }}
      helperText={false}
    />
  );
};

export default ImageOrientationInput;
