import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
} from 'react-admin';
import MyPagination from '../Pagination';
import TownFilter from './TownFilter';

const TownList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('towns')}
      filters={<TownFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" label={translate('name')} />
        <TextField source="adress" label={translate('adress')} />
        <TextField source="url" label={translate('url')} />
        <TextField source="phone" label={translate('phone')} />
        <TextField source="email" label={translate('email')} />
        <ReferenceField
          source="city"
          reference="city"
          label={translate('city')}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default TownList;
