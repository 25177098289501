import React from 'react';
import { useTranslate, Filter, useGetIdentity } from 'react-admin';
import CategoryFilter from '../CategoryFilter';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';
import StatusFilter from '../StatusFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import ReferenceFilter from '../ReferenceFilter';

const NotificationFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      <SearchFilter
        label={translate('search')}
        source="title_contains"
        {...props}
        alwaysOn={isXSmall ? false : true}
      />

      <StatusFilter label={translate('status')} {...props} alwaysOn />

      <CategoryFilter
        source="category"
        label={translate('category')}
        {...props}
      />

      {identity?.user?.isAdmin && (
        <ReferenceFilter
          key="reference"
          label={translate('reference')}
          source="reference_contains"
          {...props}
        />
      )}
    </Filter>
  );
};

export default NotificationFilter;
