import React from 'react';
import { JsonInput } from 'react-admin-json-view';
import OverlayInput from '../OverlayInput';

const DesignTab = (props: any) => {
  return (
    <>
      <OverlayInput />
      <JsonInput
        source="configuration.overlay"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </>
  );
};

export default DesignTab;
