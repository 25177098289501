import React from 'react';
import { Admin, Resource, useTranslate, Login } from 'react-admin';
import { Route, Redirect } from 'react-router-dom'; // Pour la redirection

import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from './i18n/fr';
import { Layout } from './layout';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import ArticleList from './core/Article/ArticleList';
import ArticleEdit from './core/Article/ArticleEdit';
import ArticleCreate from './core/Article/ArticleCreate';

import CategoryList from './core/Category/CategoryList';
import CategoryEdit from './core/Category/CategoryEdit';
import CategoryCreate from './core/Category/CategoryCreate';

import CountryList from './core/Country/CountryList';
import CountryEdit from './core/Country/CountryEdit';
import CountryCreate from './core/Country/CountryCreate';

import CityList from './core/City/CityList';
import CityEdit from './core/City/CityEdit';
import CityCreate from './core/City/CityCreate';

import DepartmentList from './core/Department/DepartmentList';
import DepartmentEdit from './core/Department/DepartmentEdit';
import DepartmentCreate from './core/Department/DepartmentCreate';

import DeviceList from './core/Device/DeviceList';
import DeviceEdit from './core/Device/DeviceEdit';

import DomainCreate from './core/Domain/DomainCreate';
import DomainList from './core/Domain/DomainList';
import DomainEdit from './core/Domain/DomainEdit';

import EventList from './core/Event/EventList';
import EventEdit from './core/Event/EventEdit';
import EventCreate from './core/Event/EventCreate';

import FFFList from './core/FFF/FFFList';
import FFFShow from './core/FFF/FFFShow';

import ImageList from './core/Image/ImageList';
import ImageEdit from './core/Image/ImageEdit';
import ImageCreate from './core/Image/ImageCreate';

import NotificationList from './core/Notification/NotificationList';
import NotificationEdit from './core/Notification/NotificationEdit';
import NotificationCreate from './core/Notification/NotificationCreate';

import PageList from './core/Page/PageList';
import PageEdit from './core/Page/PageEdit';
import PageCreate from './core/Page/PageCreate';

import QrcodeList from './core/Qrcode/QrcodeList';
import QrcodeEdit from './core/Qrcode/QrcodeEdit';
import QrcodeCreate from './core/Qrcode/QrcodeCreate';

import RegionList from './core/Region/RegionList';
import RegionEdit from './core/Region/RegionEdit';
import RegionCreate from './core/Region/RegionCreate';

import RoleList from './core/Role/RoleList';
import RoleEdit from './core/Role/RoleEdit';
import RoleCreate from './core/Role/RoleCreate';

import SchoolList from './core/School/SchoolList';
import SchoolShow from './core/School/SchoolShow';

import TownList from './core/Town/TownList';
import TownShow from './core/Town/TownShow';

import UserList from './core/User/UserList';
import UserEdit from './core/User/UserEdit';
import UserCreate from './core/User/UserCreate';

import VersionList from './core/Version/VersionList';
import VersionEdit from './core/Version/VersionEdit';
import VersionCreate from './core/Version/VersionCreate';

require('./App.css');

// i18nProvider
const i18nProvider = polyglotI18nProvider((locale) => {
  return frenchMessages;
}, 'fr');

const App = () => {
  const translate = useTranslate();

  return (
    <Admin
      loginPage={Login}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={Layout}
      i18nProvider={i18nProvider}
      customRoutes={[
        <Route
          key="default"
          exact
          path="/"
          render={() => <Redirect to="/domain" />}
        />,
      ]}
    >
      <Resource
        name="article"
        options={{ label: translate('articles') }}
        list={ArticleList}
        edit={ArticleEdit}
        create={ArticleCreate}
      />
      <Resource
        name="event"
        options={{ label: translate('events') }}
        list={EventList}
        edit={EventEdit}
        create={EventCreate}
      />
      <Resource
        name="notification"
        options={{ label: translate('notifications') }}
        list={NotificationList}
        edit={NotificationEdit}
        create={NotificationCreate}
      />
      <Resource
        name="page"
        options={{ label: translate('pages') }}
        list={PageList}
        edit={PageEdit}
        create={PageCreate}
      />
      <Resource
        name="device"
        options={{ label: translate('devices') }}
        list={DeviceList}
        edit={DeviceEdit}
      />
      <Resource
        name="category"
        options={{ label: translate('notifications') }}
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
      />
      <Resource
        name="image"
        options={{ label: translate('images') }}
        list={ImageList}
        edit={ImageEdit}
        create={ImageCreate}
      />
      <Resource
        name="role"
        options={{ label: translate('role') }}
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
      />
      <Resource
        name="user"
        options={{ label: translate('user') }}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
      />
      <Resource
        name="domain"
        options={{ label: translate('domain') }}
        list={DomainList}
        edit={DomainEdit}
        create={DomainCreate}
      />
      <Resource
        name="country"
        options={{ label: translate('country') }}
        list={CountryList}
        edit={CountryEdit}
        create={CountryCreate}
      />
      <Resource
        name="region"
        options={{ label: translate('region') }}
        list={RegionList}
        edit={RegionEdit}
        create={RegionCreate}
      />
      <Resource
        name="department"
        options={{ label: translate('department') }}
        list={DepartmentList}
        edit={DepartmentEdit}
        create={DepartmentCreate}
      />
      <Resource
        name="city"
        options={{ label: translate('city') }}
        list={CityList}
        edit={CityEdit}
        create={CityCreate}
      />
      <Resource
        name="town"
        options={{ label: translate('town') }}
        list={TownList}
        show={TownShow}
      />
      <Resource
        name="school"
        options={{ label: translate('school') }}
        list={SchoolList}
        show={SchoolShow}
      />
      <Resource
        name="fff"
        options={{ label: translate('ff') }}
        list={FFFList}
        show={FFFShow}
      />
      <Resource
        name="qrcode"
        options={{ label: translate('qrcode') }}
        list={QrcodeList}
        edit={QrcodeEdit}
        create={QrcodeCreate}
      />
      <Resource
        name="version"
        options={{ label: translate('version') }}
        list={VersionList}
        edit={VersionEdit}
        create={VersionCreate}
      />
    </Admin>
  );
};
export default App;
