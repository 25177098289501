import React from 'react';
import { useGetList } from 'react-admin';
import ChipField from './ChipField';
import { domainPopulate } from '../populate';

const DomainField = ({ record, source }: any) => {
  return record && (record.domain || record[source]) ? (
    <DomainChipsField record={record[source]?.id || record[source]} />
  ) : (
    <></>
  );
};

const DomainChipsField = ({ record }: any) => {
  const { data } = useGetList(
    'domain',
    { page: 1, perPage: 1 },
    { field: 'id,name', order: 'DESC' },
    { id: record, _populate: domainPopulate }
  );

  return data[record] && data[record]['name'] ? (
    <ChipField label={data[record]['name']} />
  ) : (
    <></>
  );
};

export default DomainField;
