import {
  PasswordInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity, required } from 'react-admin';
import CategoryInput from '../CategoryInput';
import DomainInput from '../DomainInput';

const MainTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <>
      <DomainInput />

      <TextInput
        source="email"
        label={translate('email')}
        validate={[required()]}
        helperText={false}
        fullWidth
      />

      <PasswordInput
        source="password"
        label={translate('password')}
        fullWidth
      />

      <CategoryInput domain={props.record?.domain} />

      {identity?.user?.isAdmin && (
        <ReferenceInput
          source="role"
          reference="role"
          label={translate('role')}
          allowEmpty={false}
          style={{ width: 250 }}
          helperText={false}
        >
          <SelectInput optionText="name" helperText={false} fullWidth />
        </ReferenceInput>
      )}
    </>
  );
};

export default MainTab;
