import React, { useState } from 'react';
import { required, useTranslate, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { DateInput, DateTimeInput } from 'ra-ui-materialui';

const useStyles = makeStyles({
  main: {},
  select: {},
});

interface Props {
  publishDate?: boolean;
  publishDateTime?: boolean;
  mode?: 'create' | 'edit';
  limited?: boolean;
}

const StatusInput = (props: Props) => {
  const translate = useTranslate();
  let classes = useStyles();
  const statusValidatePublishAt = [required()];
  const [currentStatus, setCurrentStatus] = useState<string>('published');
  const [defaultDate] = useState<Date | null>(new Date());

  let statusEnum = [
    { id: 'draft', name: translate('draft') },
    { id: 'published', name: translate('published') },
  ];

  if (!props.limited) {
    statusEnum = [
      ...statusEnum,
      ...(props.publishDate || props.publishDateTime
        ? [{ id: 'scheduled', name: translate('scheduled') }]
        : []),
      ...(props.mode === 'edit'
        ? [{ id: 'archive', name: translate('archive') }]
        : []),
    ];
  }

  // safari hack
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <div className={classes.main}>
      <SelectInput
        source="status"
        label={translate('status')}
        choices={statusEnum}
        allowEmpty={false}
        className={classes.select}
        initialValue="published"
        onChange={(e: any) => {
          setCurrentStatus(e.target.value);
        }}
        style={{ width: 250 }}
        helperText={false}
      />
      {currentStatus !== 'draft' &&
        currentStatus !== 'archive' &&
        props.publishDateTime && (
          <div>
            <DateTimeInput
              source="publish_at"
              label={translate('publish_at')}
              allowEmpty={false}
              validate={statusValidatePublishAt}
              defaultValue={defaultDate}
              style={{ width: 250 }}
              helperText={false}
            />
          </div>
        )}
      {currentStatus !== 'draft' &&
        currentStatus !== 'archive' &&
        props.publishDate && (
          <div>
            <DateInput
              source="publish_at"
              label={translate('publish_at')}
              allowEmpty={false}
              validate={statusValidatePublishAt}
              defaultValue={defaultDate}
              style={{ width: 250 }}
              helperText={false}
            />
          </div>
        )}

      {props.mode !== 'create' && !isSafari && (
        <DateInput
          source="archive_at"
          label={translate('archive_at')}
          allowEmpty={true}
          defaultValue={''}
          style={{ width: 250 }}
          helperText={false}
        />
      )}
    </div>
  );
};

export default StatusInput;
