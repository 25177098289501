import React, { useState } from 'react';
import { Button } from 'react-admin';
import { useGetIdentity } from 'react-admin';
import {
  SimpleFormIterator,
  required,
  ArrayInput,
  TextInput,
  SelectInput,
  useTranslate,
} from 'react-admin';

interface Props {
  record?: any;
  enableOpenInBrower?: boolean;
  disableSharing?: boolean;
  disableFavorite?: boolean;
  disableReload?: boolean;
}

const ActionsTab = (props: Props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const [displayConfiguration, setDisplayConfiguration] =
    useState<boolean>(false);
  const typeEnum = [
    { id: 'web', name: translate('action.web') },
    { id: 'email', name: translate('action.email') },
    { id: 'phone', name: translate('action.phone') },
    { id: 'map', name: translate('action.map') },
  ];

  const iconEnum = [
    {
      id: 'web',
      name: 'Web',
    },
    {
      id: 'envelope',
      name: 'Contact',
    },
    {
      id: 'ticket',
      name: 'Billetterie',
    },
    {
      id: 'location-dot',
      name: 'Itinéraire',
    },
    {
      id: 'phone',
      name: 'Téléphone',
    },
  ];

  return (
    <div className="panel-group">
      <p className="title">{translate('actions.title')}</p>
      <ArrayInput source="actions" label={false}>
        <SimpleFormIterator>
          <SelectInput
            label={translate('actions.type')}
            source={'type'}
            choices={typeEnum}
            initialValue="web"
            allowEmpty={false}
            style={{ width: 250 }}
            helperText={false}
            validate={required()}
          />
          <TextInput
            source={'value'}
            label={translate('actions.value')}
            style={{ width: 250 }}
            helperText={false}
            validate={required()}
          />

          <Button
            label={`${
              !displayConfiguration ? 'Afficher' : 'Masquer'
            } les options`}
            onClick={() => {
              setDisplayConfiguration(!displayConfiguration);
            }}
          />
          {displayConfiguration && (
            <SelectInput
              label={translate('actions.icon')}
              source={'icon'}
              choices={iconEnum}
              style={{ width: 250 }}
              helperText={false}
            />
          )}
          {displayConfiguration && identity?.user?.isAdmin && (
            <TextInput
              source={'icon'}
              label={translate('actions.icon')}
              style={{ width: 250 }}
              helperText={null}
            />
          )}
          {displayConfiguration && (
            <TextInput
              source={'label'}
              label={translate('actions.label')}
              style={{ width: 250 }}
              helperText={null}
            />
          )}
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

export default ActionsTab;
