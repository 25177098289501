import React from 'react';
import {
  useTranslate,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import DomainInput from '../DomainInput';
import StatusInput from '../StatusInput';
import { pageValidateDescription, pageValidateTitle } from './PageValidate';
import CategoryInput from '../CategoryInput';
import { pagePopulate } from '../../populate';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <DomainInput />
      <TextInput
        source="title"
        label={translate('title')}
        validate={pageValidateTitle}
        helperText={false}
        fullWidth
      />

      <TextInput
        source="description"
        label={translate('subtitle')}
        fullWidth
        validate={pageValidateDescription}
        helperText={false}
      />

      <div>
        <SelectInput
          source="folder"
          label={translate('folderOrPage')}
          choices={[
            { id: false, name: translate('page') },
            { id: true, name: translate('folder') },
          ]}
          allowEmpty={false}
          defaultValue={false}
          style={{ width: 250 }}
          helperText={false}
          onChange={(e: any) => {
            props.setFolder(e.target.value);
          }}
        />

        <ReferenceInput
          source="parent"
          reference="page"
          label={translate('parentFolder')}
          filter={{
            folder: true,
            status: 'published',
            ...(props?.record?.domain ? { domain: props?.record?.domain } : {}),
            _populate: pagePopulate.join(','),
          }}
          perPage={props?.record?.domain ? -1 : 50}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        >
          <SelectInput optionText="title" helperText={false} />
        </ReferenceInput>
      </div>

      <CategoryInput domain={props?.record?.domain} />

      <StatusInput publishDate={true} mode={props.mode} />
    </>
  );
};

export default MainTab;
