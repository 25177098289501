import React from 'react';
const moment = require('moment');

const EventDateField = ({ record, source }: any) => {
  const fullDay = record && record[source + '_fullDay'];
  const date =
    record && record[source]
      ? fullDay === true || fullDay === null
        ? moment(record[source]).format('DD/MM/YYYY')
        : moment(record[source]).format('DD/MM/YYYY HH:mm')
      : '';
  return date ? <span>{date}</span> : <></>;
};

export default EventDateField;
