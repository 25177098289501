import React from 'react';
import { useTranslate, List, Datagrid, useGetIdentity } from 'react-admin';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import StatusField from '../StatusField';
import ImageFilter from './ImageFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import CategoryField from '../CategoryField';
import { imagePopulate } from '../../populate';
import DomainField from '../DomainField';
import TitleField from '../TitleField';
import { ReferenceField } from 'react-admin';
import { TextField } from 'react-admin';
import { DateField } from 'react-admin';
import AccountDesactivated from '../AccountDesactivated';

const ImageList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('images')}
      pagination={<MyPagination />}
      perPage={10}
      filter={{ _populate: imagePopulate.join(',') }}
      filters={<ImageFilter />}
      filterDefaultValues={{ status: 'published' }}
      sort={{ field: 'created_at', order: 'DESC' }}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        <ImageField label={''} />
        <ImageField source="reference" label={''} />
        <TitleField source="title" label={''} />
        {!isXSmall && <CategoryField source="category" label={''} />}
        <ReferenceField
          source="page"
          reference="page"
          label={translate('page')}
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="article"
          reference="article"
          label={translate('article')}
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="event"
          reference="event"
          label={translate('event')}
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          source="notification"
          reference="notification"
          label={translate('notification')}
        >
          <TextField source="title" />
        </ReferenceField>
        <StatusField label={translate('status')} />
        {!isXSmall && (
          <DateField
            source="publish_at"
            showTime={true}
            label={translate('publish_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="created_at"
            showTime={true}
            label={translate('created_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default ImageList;
