import Compressor from 'compressorjs';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    new Compressor(file.rawFile, {
      quality: (process.env.REACT_APP_IMAGE_QUALITY || 80) / 100,
      maxWidth: process.env.REACT_APP_IMAGE_MAX_WIDTH || 1200,
      maxHeight: process.env.REACT_APP_IMAGE_MAX_HEIGHT || 1200,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedResult);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      },
      error(err) {
        resolve(null);
      },
    });
  });

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (type === 'CREATE' || type === 'UPDATE') {
    const promises = [];
    if (params.data.image && params.data.image.rawFile) {
      promises.push(
        convertFileToBase64(params.data.image).then((base64) => {
          if (base64) {
            params.data = {
              ...params.data,
              image: base64,
            };
          }
        })
      );
    } else if (params.data.clone && params.data.imageClone) {
      params.data.image = params.data.imageClone;
    } else if (params.data.removeImage) {
      params.data.image = null;
      params.data.imageCDNParams = null;
      params.data.imageCDNPath = null;
    } else {
      delete params.data.image;
    }
    if (params.data.icon && params.data.icon.rawFile) {
      promises.push(
        convertFileToBase64(params.data.icon).then((base64) => {
          params.data = {
            ...params.data,
            icon: base64,
          };
        })
      );
    } else if (params.data.removeIcon) {
      params.data.icon = null;
      params.data.iconCDNParams = null;
      params.data.iconCDNPath = null;
    } else {
      delete params.data.icon;
    }

    if (params.data.imageClone) {
      delete params.data.imageClone;
    }
    if (params.data.sent_date) {
      delete params.data.sent_date;
    }
    if (params.data.pushTickets) {
      delete params.data.sent_date;
    }
    if (params.data.pushReceipts) {
      delete params.data.sent_date;
    }

    return Promise.all(promises).then(() => {
      return requestHandler(type, resource, params);
    });
  }
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
