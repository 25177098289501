import React from 'react';
import { TopToolbar } from 'react-admin';

interface ActionProps {
  basePath?: any;
  data?: any;
  article?: any;
}

const DeviceActions = ({ data }: ActionProps) => {
  return <TopToolbar></TopToolbar>;
};

export default DeviceActions;
