import React, { useState } from 'react';
import { WidgetPages } from './WidgetPages';
import { WidgetEvents } from './WidgetEvents';
import { WidgetArticles } from './WidgetArticles';
import { WidgetDomains } from './WidgetDomains';
import { WidgetTimer } from './WidgetTimer';
import { WidgetButton } from './WidgetButton';
import { WidgetButtons } from './WidgetButtons';
import { WidgetHtml } from './WidgetHtml';
import { WidgetLoyalty } from './WidgetLoyalty';
import { BooleanInput, SelectInput, useTranslate, required } from 'react-admin';
import { useWidgets } from '../../../hooks/useWidgets';
import { Button } from 'react-admin';
import { TextInput } from 'react-admin';
import { WidgetPropulsed } from './WidgetPropulsed';

export const Widget = ({
  type,
  source,
  record,
}: {
  type: string;
  source: any;
  record: any;
}) => {
  const translate = useTranslate();
  const { typeEnum } = useWidgets();
  const [displayConfiguration, setDisplayConfiguration] =
    useState<boolean>(false);
  return (
    <>
      <SelectInput
        label={translate('widgets.type')}
        source={source('type')}
        choices={typeEnum}
        initialValue="web"
        allowEmpty={false}
        style={{ width: 250 }}
        helperText={false}
        validate={required()}
      />
      {!['buttons', 'hours', 'weather'].includes(type) && (
        <TextInput
          source={source('title')}
          label={translate('widgets.title')}
          helperText={false}
          fullWidth
        />
      )}

      <p>
        <Button
          label={`${
            !displayConfiguration ? 'Afficher' : 'Masquer'
          } les options`}
          onClick={() => {
            setDisplayConfiguration(!displayConfiguration);
          }}
        />
      </p>

      {displayConfiguration && (
        <>
          <BooleanInput
            source={source('disabled')}
            label={translate('widgets.disabled')}
            helperText={false}
          />
          <BooleanInput
            source={source('lowDataMode')}
            label={translate('configuration.lowDataMode')}
            helperText={false}
            defaultValue={true}
          />
          <SelectInput
            label={translate('theme')}
            source={source('theme')}
            choices={[
              { id: 'light', name: translate('light') },
              { id: 'dark', name: translate('dark') },
            ]}
            allowEmpty={true}
          />

          {type === 'pages' && <WidgetPages source={source} record={record} />}
          {type === 'events' && <WidgetEvents source={source} />}
          {(type === 'news' || type === 'articles') && (
            <WidgetArticles source={source} />
          )}
          {type === 'notifications' && <WidgetArticles source={source} />}
          {type === 'domains' && <WidgetDomains source={source} />}
          {type === 'timer' && <WidgetTimer source={source} />}
          {type === 'button' && <WidgetButton source={source} />}
          {type === 'buttons' && <WidgetButtons source={source} />}
          {type === 'html' && <WidgetHtml source={source} />}
          {type === 'loyalty' && <WidgetLoyalty source={source} />}
          {type === 'propulsed' && <WidgetPropulsed source={source} />}
        </>
      )}
    </>
  );
};
