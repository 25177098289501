import { BooleanInput, ImageField, ImageInput } from 'ra-ui-materialui';
import React, { useMemo } from 'react';
import { useTranslate, useGetIdentity, TextInput } from 'react-admin';
import { getMaxUploadSize } from './Validate';
import Typography from '@material-ui/core/Typography';

const IMAGEKIT_DOMAIN = 'imagekit.io';

type ImageOrientation = 'portrait' | 'landscape' | 'square' | 'none';

/**
 * Return the image dimensions (width/height) depending on a given orientation.
 *
 * @param format   Orientation of the image
 * @returns Dimensions
 */
function getFormatDimensions(
  format: Exclude<ImageOrientation, 'none'> | undefined
): { width: number; height: number } {
  switch (format) {
    case 'portrait':
      return {
        width: 298,
        height: 421,
      };
    case 'landscape':
      return {
        width: 480,
        height: 300,
      };
    case 'square':
    default:
      return {
        width: 300,
        height: 300,
      };
  }
}

export function formatImage(value: any) {
  if (!value || typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

/**
 * Return the URL query of a given URL and given dimensions.
 *
 * @param imageUrl URL of the image
 * @param width    Width
 * @param height   Image
 * @returns URL query
 */
function getQueryParams(imageUrl: string, width: number, height: number) {
  const queryParamsSeparator =
    imageUrl && imageUrl.indexOf('?') !== -1 ? '&' : '?';

  let queryParams: Record<string, string> = {
    width: `${width}`,
    height: `${height}`,
  };

  if (imageUrl.includes(IMAGEKIT_DOMAIN)) {
    queryParams = {
      tr: `w-${width},h-${height}`,
    };
  }

  const searchParams = new URLSearchParams(queryParams);

  return `${queryParamsSeparator}${searchParams.toString()}`;
}

/**
 * Transform a given image URL to an URL pointing to an thumbnail.
 *
 * @param imageUrl URL of the image
 * @param format   Orientation of the image
 * @returns New URL
 */
function generateThumbnailUrl(
  imageUrl = '',
  format?: ImageOrientation
): string {
  if (!imageUrl || format === 'none') {
    return imageUrl;
  }

  const { width, height } = getFormatDimensions(format);
  const queryParams = getQueryParams(imageUrl, width, height);

  return `${imageUrl}${queryParams}`;
}

const useThumbnail = (
  props: any,
  format: ImageOrientation = 'none',
  property = 'image'
) =>
  useMemo(
    () =>
      generateThumbnailUrl(
        props?.record?.[`${property}CDNUrl`] ||
          props?.record?.[`${property}Url`],
        format
      ),
    [format, property, props?.record]
  );

const ImageTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const thumbnailUrl = useThumbnail(props);

  return (
    <>
      <p style={{ textAlign: 'center' }}>
        <Typography variant="caption" style={{ display: 'none' }}>
          Vos images sont trop volumineuses ?{' '}
          <a
            href="https://squoosh.app/"
            target="_blank"
            rel="noreferrer"
            className="focus"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            SQUOOSH
          </a>{' '}
          est l'outil qu'il vous faut !
        </Typography>

        <Typography variant="caption">
          En manque d'inspiration pour vos images ?{' '}
          <a
            href="https://www.pexels.com/"
            target="_blank"
            rel="noreferrer"
            className="focus"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            PEXELS
          </a>{' '}
          est la banque d'images libres de droit incontournable !
        </Typography>
      </p>

      <ImageInput
        source="image"
        format={formatImage}
        label={null}
        accept=".jpg,.jpeg,.png,.webp"
        placeholder={translate('imageInput')}
        helperText={props.helperText}
      >
        <ImageField label={translate('image')} />
      </ImageInput>

      <TextInput
        source="imageClone"
        defaultValue={props?.record?.image}
        type="hidden"
        style={{ visibility: 'hidden' }}
        helperText={false}
      />

      <img src={thumbnailUrl} alt="" style={{ maxHeight: 150 }} />

      {thumbnailUrl && (
        <>
          <BooleanInput
            source="removeImage"
            label={translate('removeImage')}
            defaultValue={false}
            helperText={false}
          />
          <BooleanInput
            source="configuration.hideImage"
            label={translate('configuration.hideImage')}
            helperText={false}
          />
        </>
      )}

      {identity?.user?.isAdmin && (
        <BooleanInput
          source="configuration.enableImageDownload"
          label={translate('enableImageDownload')}
          helperText={false}
        />
      )}
    </>
  );
};

export const IconTab = (props: any) => {
  const translate = useTranslate();
  const thumbnailUrl = useThumbnail(props, 'none', 'icon');

  return (
    <>
      <ImageInput
        source="icon"
        format={formatImage}
        label={null}
        accept="image/*"
        placeholder={translate('iconInput')}
        maxSize={getMaxUploadSize()}
        helperText={props.helperText}
      >
        <ImageField source="icon" label={translate('icon')} />
      </ImageInput>

      <img src={thumbnailUrl} alt="" style={{ maxHeight: 150 }} />

      {thumbnailUrl && (
        <BooleanInput
          source="removeIcon"
          label={translate('removeIcon')}
          defaultValue={false}
          helperText={false}
        />
      )}
    </>
  );
};

export default ImageTab;
