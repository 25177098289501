const resourcePopulate = [
  'id',
  'title',
  'imageUrl',
  'imageCDNUrl',
  'publish_at',
  'archive_at',
  'created_at',
  'updated_at',
  'content',
  'openInBrowser',
  'category',
  'domain',
  'configuration',
  'status',
  'description',
  'reference',
  'actions',
  'adress',
  'city',
  'geometry',
];

export const pagePopulate = [
  ...resourcePopulate,
  'folder',
  'parent',
  'is_pinned',
  'hideMenu',
  'sort',
];

export const articlePopulate = [...resourcePopulate];

export const eventPopulate = [
  ...resourcePopulate,
  'start_date',
  'start_date_fullDay',
  'end_date',
  'end_date_fullDay',
  'notification',
];

export const notificationPopulate = [...resourcePopulate, 'body', 'sent_date'];

export const imagePopulate = [
  ...resourcePopulate,
  'page',
  'article',
  'notification',
  'event',
];

export const categoryPopulate = [
  ...resourcePopulate,
  'name',
  'iconUrl',
  'iconCDNUrl',
  'hiddable',
  'sort',
];

export const domainPopulate = [
  'id',
  'uuid',
  'name',
  'slug',
  'parent',
  'description',
  'iconUrl',
  'iconCDNUrl',
  'imageUrl',
  'imageCDNUrl',
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'linkedin',
  'url',
  'email',
  'phone',
  'city',
  'configuration',
  'type,pack',
  'adress',
  'password',
  'status',
  'hours',
  'created_at',
  'updated_at',
  'namespaces',
];
