import { useGetOne } from 'ra-core';
import { BooleanInput, DateInput, DateTimeInput } from 'ra-ui-materialui';
import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

const DatesTab = (props: any) => {
  const translate = useTranslate();
  const { data } = useGetOne('event', props.id || 0);

  const [isStartFullDay, setIsStartFullDay] = useState<boolean>(true);
  const [isEndFullDay, setIsEndFullDay] = useState<boolean>(true);

  useEffect(() => {
    setIsStartFullDay(data ? data.start_date_fullDay : true);
    setIsEndFullDay(data ? data.end_date_fullDay : true);
  }, [data]);

  return (
    <>
      <div>
        {isStartFullDay ? (
          <DateInput
            source="start_date"
            label={translate('start_date')}
            allowEmpty={true}
            style={{ width: 250 }}
            helperText={false}
          />
        ) : (
          <DateTimeInput
            source="start_date"
            label={translate('start_date')}
            allowEmpty={true}
            style={{ width: 250 }}
            helperText={false}
          />
        )}
        <BooleanInput
          source="start_date_fullDay"
          label={translate('fullDay')}
          defaultValue={true}
          onChange={(e: any) => {
            setIsStartFullDay(e);
          }}
          helperText={false}
        />
      </div>

      <div>
        {isEndFullDay ? (
          <DateInput
            source="end_date"
            label={translate('end_date')}
            allowEmpty={true}
            style={{ width: 250 }}
            helperText={false}
          />
        ) : (
          <DateTimeInput
            source="end_date"
            label={translate('end_date')}
            allowEmpty={true}
            style={{ width: 250 }}
            helperText={false}
          />
        )}
        <BooleanInput
          source="end_date_fullDay"
          label={translate('fullDay')}
          defaultValue={true}
          onChange={(e: any) => {
            setIsEndFullDay(e);
          }}
          helperText={false}
        />
      </div>
    </>
  );
};

export default DatesTab;
