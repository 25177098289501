import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  NumberField,
  TextField,
} from 'react-admin';
import MyPagination from '../Pagination';
import CountryFilter from './CountryFilter';

const CountryList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      title={translate('countries')}
      filters={<CountryFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export default CountryList;
