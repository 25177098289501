import React from 'react';
import { FormTab, useGetIdentity, useTranslate, Edit } from 'react-admin';
import { TabbedForm } from 'ra-ui-materialui';
import EditToolbar from '../EditToolBar';
import MainTab from './MainTab';
import ContentTab from './ContentTab';
import { useGetOne } from 'ra-core';
import SectionTab from './SectionTab';
import RootTab from './RootTab';
import DesignTab from './DesignTab';

const ImageEdit = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { data } = useGetOne('image', props.id || 0);

  return (
    <Edit title={translate('images')} {...props}>
      <TabbedForm
        redirect="list"
        toolbar={<EditToolbar hideDeletion={!identity?.user?.isAdmin} />}
      >
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" data={data} />
        </FormTab>

        <FormTab label={translate('image')}>
          <ContentTab data={data} />
        </FormTab>

        <FormTab label={translate('sections')}>
          <SectionTab {...props} />
        </FormTab>

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default ImageEdit;
