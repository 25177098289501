import Chip from '@material-ui/core/Chip';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Theme } from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: {},
});

const ChipField = ({ record, source, label, color, backgroundColor }: any) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  let classes = useStyles();

  if (!label && !record) {
    return <></>;
  }

  label = label || record[source];

  return record || label ? (
    <div className={classes.main}>
      <Chip
        size="small"
        className={classes.chip}
        style={{
          backgroundColor: backgroundColor,
          color: color,
          margin: 4,
          minWidth: isXSmall ? 0 : 100,
        }}
        label={label}
      />
    </div>
  ) : (
    <></>
  );
};

export default ChipField;
