import React from 'react';
import { useTranslate, Filter, useGetIdentity } from 'react-admin';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';
import StatusFilter from '../StatusFilter';
import { useMediaQuery, Theme } from '@material-ui/core';

const CategoryFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn={isXSmall ? false : true}
      />

      <StatusFilter label={translate('status')} {...props} alwaysOn />
    </Filter>
  );
};

export default CategoryFilter;
