import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import DomainFilter from './DomainFilter';

interface Props {
  validate?: any;
}

const DomainInput = (props: Props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <div
      style={{
        visibility: identity?.user.isAdmin ? 'visible' : 'hidden',
        position: identity?.user.isAdmin ? 'relative' : 'absolute',
      }}
    >
      <DomainFilter source="domain" label={translate('domain')} />
    </div>
  );
};

export default DomainInput;
