import React from 'react';
import { useTranslate, TextInput } from 'react-admin';
import CategoryInput from '../CategoryInput';
import DomainInput from '../DomainInput';
import StatusInput from '../StatusInput';
import {
  articleValidateDescription,
  articleValidateTitle,
} from './ArticleValidate';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <DomainInput />
      <TextInput
        source="title"
        label={translate('title')}
        validate={articleValidateTitle}
        fullWidth
        helperText={false}
      />
      <TextInput
        source="description"
        label={translate('subtitle')}
        fullWidth
        validate={articleValidateDescription}
        helperText={false}
      />
      <CategoryInput domain={props.record?.domain} />

      <StatusInput publishDateTime={true} mode={props.mode} />
    </>
  );
};

export default MainTab;
