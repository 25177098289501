import React from 'react';
import { useTranslate, TextInput } from 'react-admin';

export const WidgetHtml = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <TextInput
        source={source('content')}
        label={translate('widgets.htmlCode')}
        helperText={false}
        fullWidth
      />
    </div>
  );
};
