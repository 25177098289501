import React from 'react';
import { useTranslate, List, Datagrid, TextField } from 'react-admin';
import MyPagination from '../Pagination';

const VersionList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('versions')}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="key" label={translate('key')} />
        <TextField source="value" label={translate('value')} />
      </Datagrid>
    </List>
  );
};

export default VersionList;
