import { fetchUtils } from 'react-admin';
import simpleRestProvider from './strapiRestProvider';
import addUploadFeature from './addUploadFeature';

// dataProvider
const requestHeaders: HeadersInit = new Headers();
requestHeaders.set('Content-Type', 'application/json');
const httpClient = (url: string, options = { headers: requestHeaders }) => {
  if (!options.headers)
    options.headers = new Headers({ Accept: 'application/json' });
  const auth = localStorage.getItem('auth');
  const { jwt } = auth ? JSON.parse(auth) : null;
  options.headers.set('Authorization', `Bearer ${jwt}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  httpClient
);
export default addUploadFeature(dataProvider);
