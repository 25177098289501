import React, { useState } from 'react';
import { useTranslate, Create, useGetIdentity } from 'react-admin';
import { FormTab, TabbedForm } from 'ra-ui-materialui';
import ContentTab from '../ContentTab';
import ImageTab from '../ImageTab';
import MainTab from './MainTab';
import RootTab from './RootTab';
import AdressTab from '../AdressTab';
import { useGetOne } from 'ra-core';
import ConfigTab from './ConfigTab';
import DesignTab from './DesignTab';

const PageCreate = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { data } = useGetOne('page', props.id || 0);
  const [isFolder, setIsFolder] = useState<boolean>(data?.folder);

  const pageDefaultValue = () => ({});

  return (
    <Create title={translate('pages')} {...props}>
      <TabbedForm redirect="list" initialValues={pageDefaultValue}>
        <FormTab label={translate('infos')}>
          <MainTab mode="create" setFolder={setIsFolder} />
        </FormTab>
        <FormTab label={translate('image')}>
          <ImageTab />
        </FormTab>
        <FormTab label={translate('content')}>
          <ContentTab
            enableOpenInBrower={
              identity?.user?.isAdmin ||
              identity?.user?.domain?.configuration?.enableOpenInBrowser
            }
          />
        </FormTab>
        {(identity?.user?.isAdmin ||
          identity?.user?.domain?.configuration?.enableMap) && (
          <FormTab label={translate('adress')}>
            <AdressTab />
          </FormTab>
        )}
        <FormTab label={translate('configuration.label')}>
          <ConfigTab isFolder={isFolder} />
        </FormTab>

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};

export default PageCreate;
