import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  useGetIdentity,
  DateField,
} from 'react-admin';
import MyPagination from '../Pagination';
import NotificationFilter from './NotificationFilter';
import ImageField from '../ImageField';
import ChipField from '../ChipField';
import StatusField from '../StatusField';
import { useMediaQuery, Theme } from '@material-ui/core';
import CategoryField from '../CategoryField';
import UrlField from '../UrlField';
import Typography from '@material-ui/core/Typography';
import { notificationPopulate } from '../../populate';
import DomainField from '../DomainField';
import TitleField from '../TitleField';
import { CloneButton } from '../CloneButton';
import AccountDesactivated from '../AccountDesactivated';

const SentField = ({ record }: any) => {
  const translate = useTranslate();
  let color;
  let label;

  if (!record) {
    return <></>;
  }

  if (record.sent_date) {
    color = `rgba(0, 148, 160,1)`;
    label = 'is_sent';
  } else if (record.status === 'draft') {
    color = '#FF3869';
    label = 'is_not_sent';
  } else if (record?.status === 'scheduled') {
    color = `#FF3869`;
    label = 'is_not_sent';
  } else if (record?.status === 'published') {
    color = `#FF3869`;
    label = 'is_not_sent';
  } else if (record?.status === 'archive') {
    color = '#FF3869';
    label = 'is_not_sent';
  }

  return (
    record && (
      <ChipField record={record} color={color} label={translate(label)} />
    )
  );
};

const NotificationList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <>
      {identity?.user?.domain?.configuration?.notifications?.startHour &&
        identity?.user?.domain?.configuration?.notifications?.endHour && (
          <Typography variant="caption">
            <p className="warning" style={{ marginLeft: 5, marginRight: 5 }}>
              <strong>
                L'envoi des notifications est réalisé entre{' '}
                {
                  identity?.user?.domain?.configuration?.notifications
                    ?.startHour
                }{' '}
                et{' '}
                {identity?.user?.domain?.configuration?.notifications?.endHour}
              </strong>
            </p>
          </Typography>
        )}

      <List
        title={translate('notifications')}
        pagination={<MyPagination />}
        perPage={10}
        filter={{ _populate: notificationPopulate.join(',') }}
        filters={<NotificationFilter />}
        filterDefaultValues={{ status: 'published' }}
        exporter={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        bulkActionButtons={identity?.user?.isAdmin}
        {...props}
      >
        <Datagrid rowClick="edit">
          {identity?.user?.isAdmin && (
            <DomainField source="domain" label={translate('domain')} />
          )}
          <ImageField label={''} />
          <TitleField source="title" label={''} />

          {!isXSmall && <CategoryField source="category" label={''} />}
          {!isXSmall && <UrlField source="reference" label="" />}
          {identity?.user?.isAdmin && (
            <DateField
              source="sent_date"
              showTime={true}
              label={translate('sent_real_date')}
            />
          )}
          {!isXSmall && <StatusField />}
          <SentField />
          {!isXSmall && (
            <DateField
              source="publish_at"
              showTime={true}
              label={translate('publish_at')}
            />
          )}
          {identity?.user?.isAdmin && (
            <DateField
              source="created_at"
              showTime={true}
              label={translate('created_at')}
            />
          )}
          {identity?.user?.isAdmin && (
            <DateField
              source="updated_at"
              showTime={true}
              label={translate('updated_at')}
            />
          )}
          <CloneButton />
        </Datagrid>
      </List>
    </>
  ) : (
    <AccountDesactivated />
  );
};

export default NotificationList;
