import React from 'react';
import {
  useGetIdentity,
  SimpleFormIterator,
  ArrayInput,
  useTranslate,
  FormDataConsumer,
} from 'react-admin';
import { Tab } from './Tabs/Tab';

const TabsTab = () => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  return (
    <>
      {identity?.user?.isAdmin && (
        <ArrayInput source="configuration.tabs" label={translate('tabs.title')}>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource }: { scopedFormData: any; getSource: any }) => {
                return <Tab source={getSource} />;
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </>
  );
};

export default TabsTab;
