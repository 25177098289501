import { ReferenceInput, SelectInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import {
  articlePopulate,
  eventPopulate,
  notificationPopulate,
  pagePopulate,
} from '../../populate';

const SectionTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const domainId = props?.record?.domain || identity?.user?.domain?.id;
  return (
    <>
      {domainId && (
        <>
          <div>
            <ReferenceInput
              source="page"
              reference="page"
              label={translate('page')}
              filter={{
                status: 'published',
                folder: false,
                ...(domainId ? { domain: domainId } : {}),
                _populate: pagePopulate.join(','),
              }}
              perPage={props?.record?.domain ? -1 : 50}
              allowEmpty={true}
              style={{ width: 250 }}
              helperText={false}
            >
              <SelectInput optionText="title" helperText={false} />
            </ReferenceInput>
          </div>

          <div>
            <ReferenceInput
              source="article"
              reference="article"
              label={translate('article')}
              filter={{
                status: 'published',
                ...(props?.record?.domain
                  ? { domain: props?.record?.domain }
                  : {}),
                _populate: articlePopulate.join(','),
              }}
              perPage={props?.record?.domain ? -1 : 50}
              allowEmpty={true}
              style={{ width: 250 }}
              helperText={false}
            >
              <SelectInput optionText="title" helperText={false} />
            </ReferenceInput>
          </div>

          <div>
            <ReferenceInput
              source="event"
              reference="event"
              label={translate('event')}
              filter={{
                status: 'published',
                ...(props?.record?.domain
                  ? { domain: props?.record?.domain }
                  : {}),
                _populate: eventPopulate.join(','),
              }}
              perPage={props?.record?.domain ? -1 : 50}
              allowEmpty={true}
              style={{ width: 250 }}
              helperText={false}
            >
              <SelectInput optionText="title" helperText={false} />
            </ReferenceInput>
          </div>

          <div>
            <ReferenceInput
              source="notification"
              reference="notification"
              label={translate('notification')}
              filter={{
                status: 'published',
                ...(props?.record?.domain
                  ? { domain: props?.record?.domain }
                  : {}),
                _populate: notificationPopulate.join(','),
              }}
              perPage={50}
              allowEmpty={true}
              style={{ width: 250 }}
              helperText={false}
            >
              <SelectInput optionText="title" helperText={false} />
            </ReferenceInput>
          </div>
        </>
      )}
    </>
  );
};

export default SectionTab;
