import React from 'react';
import { useTranslate, Create, SimpleForm } from 'react-admin';
import MainTab from './MainTab';

const CountryCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <Create title={translate('countries')} {...props}>
      <SimpleForm redirect="list">
        <MainTab />
      </SimpleForm>
    </Create>
  );
};
export default CountryCreate;
