import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  NumberField,
  TextField,
  ReferenceField,
} from 'react-admin';
import MyPagination from '../Pagination';
import RegionFilter from './RegionFilter';

const RegionList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      title={translate('regions')}
      filters={<RegionFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" />
        <ReferenceField
          source="country"
          reference="country"
          label={translate('country')}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default RegionList;
