import { AutocompleteInput, ReferenceInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { domainPopulate } from '../populate';

interface Props {
  label: string;
  source: string;
}

const useStyles = makeStyles({
  main: {},
});

const DomainFilter = (props: Props) => {
  const translate = useTranslate();
  let classes = useStyles();
  return (
    <div className={classes.main}>
      <ReferenceInput
        source={props.source}
        reference="domain"
        label={translate('domain')}
        perPage={50}
        allowEmpty={false}
        filter={{
          pack_null: false,
          status: 'published',
          _populate: domainPopulate,
        }}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </div>
  );
};

export default DomainFilter;
