import { DateField } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, List, Datagrid, useGetIdentity } from 'react-admin';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import StatusField from '../StatusField';
import EventDateField from './EventDateField';
import EventFilter from './EventFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import CategoryField from '../CategoryField';
import UrlField from '../UrlField';
import { eventPopulate } from '../../populate';
import DomainField from '../DomainField';
import TitleField from '../TitleField';
import CheckIcon from '@material-ui/icons/Check';
import { CloneButton } from '../CloneButton';
import AccountDesactivated from '../AccountDesactivated';

const NotifyField = ({ record }: any) => {
  const { identity } = useGetIdentity();
  if (!record) {
    return <></>;
  }
  return record.notification === true ||
    (identity?.user?.domain?.configuration?.events?.notify &&
      record.notification === null) ? (
    <CheckIcon />
  ) : (
    <></>
  );
};

const EventList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('events')}
      pagination={<MyPagination />}
      perPage={10}
      filter={{ _populate: eventPopulate.join(',') }}
      filters={<EventFilter />}
      filterDefaultValues={{ status: 'published' }}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={identity?.user?.isAdmin}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        <ImageField label={''} />
        <TitleField source="title" label={''} />

        {!isXSmall && <CategoryField source="category" label={''} />}
        {!isXSmall && <UrlField source="reference" label="" />}
        {!isXSmall && (
          <EventDateField source="start_date" label={translate('start_date')} />
        )}
        {!isXSmall && (
          <EventDateField source="end_date" label={translate('end_date')} />
        )}
        {!isXSmall && <StatusField label={translate('status')} />}
        {!isXSmall && (
          <DateField
            source="publish_at"
            showTime={false}
            label={translate('publish_at')}
          />
        )}
        <NotifyField
          label={translate('configuration.events.notify_short', {
            days: process.env.REACT_APP_EVENT_NOTIFY_DAY_BEFORE || 3,
          })}
        />
        {identity?.user?.isAdmin && (
          <DateField
            source="created_at"
            showTime={true}
            label={translate('created_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
        <CloneButton />
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default EventList;
