import React from 'react';
import { useTranslate, Edit, SimpleForm } from 'react-admin';
import EditToolbar from '../EditToolBar';
import MainTab from './MainTab';

const UserEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit title={translate('users')} {...props}>
      <SimpleForm redirect="list" toolbar={<EditToolbar />}>
        <MainTab mode="edit" />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
