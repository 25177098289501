import React from 'react';
import { useTranslate, SimpleForm, Edit } from 'react-admin';
import EditToolbar from '../EditToolBar';
import MainTab from './MainTab';

const CountryEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit title={translate('qrcodes')} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <MainTab />
      </SimpleForm>
    </Edit>
  );
};
export default CountryEdit;
