import React from 'react';
import { useTranslate, TextInput } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
export const WidgetButton = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <TextInput
        source={source('title')}
        label={translate('widgets.title')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source={source('icon')}
        label={translate('widgets.icon')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source={source('target')}
        label={translate('widgets.target')}
        helperText={false}
        fullWidth
      />
      <JsonInput
        source={source('params')}
        label={translate('widgets.params')}
        helperText={false}
      />
    </div>
  );
};
