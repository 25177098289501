import React from 'react';
import {
  useTranslate,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import SearchFilter from '../SearchFilter';

const CityFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn
      />
      <ReferenceInput
        label={translate('country')}
        source="country"
        reference="country"
        perPage={10}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        label={translate('region')}
        source="region"
        reference="region"
        perPage={10}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        label={translate('department')}
        source="department"
        reference="department"
        perPage={10}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
    </Filter>
  );
};

export default CityFilter;
