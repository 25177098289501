import { BooleanInput, NumberInput, TextInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';
import DomainInput from '../DomainInput';
import StatusInput from '../StatusInput';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <DomainInput />
      <TextInput
        source="name"
        label={translate('name')}
        helperText={false}
        fullWidth
      />
      <BooleanInput
        source="hiddable"
        label={translate('hiddable')}
        defaultValue={true}
        helperText={false}
      />
      <NumberInput
        source="sort"
        label={translate('sort')}
        style={{ width: 250 }}
        helperText={false}
      />
      <StatusInput mode={props.mode} />
    </>
  );
};

export default MainTab;
