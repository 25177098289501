import React from 'react';
import { TextInput } from 'react-admin';

const Tags = (props: any) => {
  const tags = props?.record?.configuration?.tags;
  const array = Object.entries(tags);
  return array.length ? (
    <div>
      {array.map((tag) => (
        <TextInput
          label={props?.record?.configuration?.tags?.[tag[0]].label}
          source={`configuration.tags.${tag[0]}.value`}
          fullWidth
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

export default Tags;
