import { AutocompleteInput, NumberInput } from 'ra-ui-materialui';
import React, { useEffect, useState } from 'react';
import { useGetOne } from 'react-admin';
import {
  useTranslate,
  TextInput,
  ReferenceInput,
  useGetIdentity,
} from 'react-admin';
import LocationInput, { Geometry } from './LocationInput';

const AdressTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const [geometry, setGeometry] = useState<Geometry>();
  const { record } = props;

  // get city
  const { data: city } = useGetOne(
    'city',
    record?.city?.id || record?.city || 0
  );

  useEffect(() => {
    setGeometry({
      latitude: record?.geometry?.latitude || city?.latitude,
      latitudeDelta:
        record?.geometry?.latitudeDelta || city?.latitudeDelta || 20,
      longitude: record?.geometry?.longitude || city?.longitude,
      longitudeDelta:
        record?.geometry?.longitudeDelta || city?.longitudeDelta || 20,
    });
  }, [record, city]);

  return (
    <>
      <TextInput
        source="adress"
        label={translate('adress')}
        helperText={false}
        fullWidth
      />
      <ReferenceInput
        source="city"
        reference="city"
        label={translate('city')}
        fullWidth
        allowEmpty={true}
        perPage={50}
        helperText={false}
      >
        <AutocompleteInput
          fullWidth
          optionText={(record) => {
            if (record) {
              return `${record.name} (${record.postal})`;
            } else return translate('noCity');
          }}
        />
      </ReferenceInput>
      {identity?.user?.isAdmin && (
        <div className="panel-group">
          <p className="title">{translate('geometry.title')}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api-adresse.data.gouv.fr/search/?q=${record.adress} ${city?.postal} ${city?.name}`}
          >
            Obtenir les coordonnées géographiques
          </a>
          <LocationInput
            latitude="geometry.latitude"
            defaultLatitude={geometry?.latitude}
            longitude="geometry.longitude"
            defaultLongitude={geometry?.longitude}
            latitudeDelta="geometry.latitudeDelta"
            defaultLatitudeDelta={geometry?.latitudeDelta}
            longitudeDelta="geometry.longitudeDelta"
            defaultLongitudeDelta={geometry?.longitudeDelta}
          />
        </div>
      )}
      {identity?.user?.isAdmin && (
        <div className="panel-group">
          <p className="title">{translate('marker.title')}</p>
          <TextInput
            source="configuration.marker.image"
            label={translate('marker.image')}
            helperText={false}
            fullWidth
          />
          <TextInput
            source="configuration.marker.label"
            label={translate('marker.label')}
            helperText={false}
            fullWidth
          />
          <TextInput
            source="configuration.marker.backgroundColor"
            label={translate('marker.backgroundColor')}
            helperText={false}
            fullWidth
          />
          <NumberInput
            source="configuration.marker.size"
            label={translate('marker.size')}
            helperText={false}
            defaultValue={30}
            fullWidth
          />
          <LocationInput
            latitude="configuration.marker.initialRegion.latitude"
            longitude="configuration.marker.initialRegion.longitude"
            latitudeDelta="configuration.marker.initialRegion.latitudeDelta"
            longitudeDelta="configuration.marker.initialRegion.longitudeDelta"
          />
        </div>
      )}
    </>
  );
};

export default AdressTab;
