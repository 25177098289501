import React from 'react';
import { useTranslate, SelectInput } from 'react-admin';

interface Props {
  label: string;
  alwaysOn?: boolean;
  limited?: false;
}

const StatusFilter = (props: Props) => {
  const translate = useTranslate();

  let statusEnum = [
    { id: 'published', name: translate('published') },
    { id: 'archive', name: translate('archive') },
  ];

  if (!props.limited) {
    statusEnum = [
      { id: 'draft', name: translate('draft') },
      { id: 'scheduled', name: translate('scheduled') },
      ...statusEnum,
    ];
  }

  return (
    <SelectInput
      source="status"
      reference={translate('status')}
      choices={statusEnum}
      allowEmpty={true}
      style={{ width: 125 }}
      {...props}
    />
  );
};

export default StatusFilter;
