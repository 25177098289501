import React from 'react';
import { NumberInput } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { TextInput } from 'react-admin';
import { useTranslate, SelectInput } from 'react-admin';

interface Props {}

const TargetInput = (props: Props) => {
  const translate = useTranslate();
  const targetEnum = [
    { id: 'Articles', name: 'Articles' },
    { id: 'Events', name: 'Events' },
    { id: 'Pages', name: 'Pages' },
    { id: 'Page', name: 'Page' },
    { id: 'Notifications', name: 'Notifications' },
    { id: 'Domain', name: 'Domain' },
    { id: 'SearchDomain', name: 'SearchDomain' },
    { id: 'RateApp', name: 'RateApp' },
    { id: 'Hours', name: 'Hours' },
    { id: 'Images', name: 'Images' },
    { id: 'Loyalty', name: 'Loyalty' },
    { id: 'BarCode', name: 'BarCode' },
    { id: 'GolfScoreCard', name: 'Carte Scores (Golf)' },
    { id: 'BasketRanking', name: 'Classement (Basket)' },
  ];

  return (
    <>
      <SelectInput
        label={translate('configuration.target')}
        source={'configuration.target'}
        choices={targetEnum}
        allowEmpty={true}
        style={{ width: 250 }}
        helperText={false}
      />
      <BooleanInput
        source="configuration.params.fullscreen"
        label={translate('configuration.fullscreen')}
        helperText={false}
      />
      <NumberInput
        source="configuration.params.id"
        label={translate('configuration.id')}
        helperText={false}
      />
      <TextInput
        source="configuration.target"
        label={translate('configuration.target')}
        fullWidth
        helperText={false}
      />
    </>
  );
};

export default TargetInput;
