import React from 'react';
import { useTranslate, Create, SimpleForm } from 'react-admin';
import MainTab from './MainTab';

const UserCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <Create title={translate('users')} {...props}>
      <SimpleForm redirect="list">
        <MainTab mode="create" label={translate('infos')} />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
