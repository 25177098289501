import React from 'react';
import { NumberInput } from 'react-admin';
import { useTranslate } from 'react-admin';

export const WidgetPropulsed = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <NumberInput source={source('domain')} label={translate('domain')} />
    </div>
  );
};
