import React from 'react';
import { useTranslate, List, Datagrid, TextField } from 'react-admin';
import MyPagination from '../Pagination';

const RoleList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('roles')}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label={translate('id')} />
        <TextField source="name" label={translate('name')} />
        <TextField source="description" label={translate('description')} />
        <TextField source="type" label={translate('type')} />
      </Datagrid>
    </List>
  );
};

export default RoleList;
