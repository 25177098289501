import React from 'react';
import {
  useTranslate,
  Filter,
  useGetIdentity,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { SelectInput } from 'ra-ui-materialui';
import SearchFilter from '../SearchFilter';
import StatusFilter from '../StatusFilter';
import { domainePackEnum, domaineTypeEnum } from './DomainValidate';
import { domainPopulate } from '../../populate';

const DomainFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  if (!identity?.user?.isAdmin) {
    return <></>;
  }
  return (
    <Filter {...props}>
      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn
      />
      <SearchFilter label={translate('Url')} source="url_contains" {...props} />
      <ReferenceInput
        source="city"
        reference="city"
        label={translate('city')}
        perPage={50}
        helperText={false}
      >
        <AutocompleteInput
          optionText={(record: any) => {
            return record?.id ? `${record?.name}` : '';
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="parent"
        reference="domain"
        label={translate('parent')}
        perPage={50}
        filter={{ pack_null: false, _populate: domainPopulate }}
        helperText={false}
      >
        <AutocompleteInput
          debounce={1000}
          optionText={(record: any) => {
            return record?.id ? `${record?.name}` : '';
          }}
        />
      </ReferenceInput>
      <SelectInput
        source="type"
        choices={domaineTypeEnum}
        fullWidth
        allowEmpty={true}
        helperText={false}
      />
      <SelectInput
        source="pack"
        choices={domainePackEnum}
        fullWidth
        allowEmpty={true}
        helperText={false}
      />
      <StatusFilter
        key="status"
        label={translate('status')}
        limited={true}
        {...props}
        alwaysOn
      />
    </Filter>
  );
};

export default DomainFilter;
