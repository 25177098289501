import React from 'react';
import { Toolbar } from '@material-ui/core';
import { SaveButton } from 'react-admin';
import { DeleteWithConfirmButton } from 'ra-ui-materialui';

const EditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
      {!props.hideDeletion && <DeleteWithConfirmButton {...props} />}
    </Toolbar>
  );
};

export default EditToolbar;
