import React from 'react';
import { useTranslate, Filter, useGetIdentity } from 'react-admin';
import CategoryFilter from '../CategoryFilter';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';
import StatusFilter from '../StatusFilter';
import { ReferenceInput } from 'react-admin';
import { pagePopulate } from '../../populate';
import { AutocompleteInput } from 'react-admin';

const ImageFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const domainId = props?.record?.domain || identity?.user?.domain?.id;
  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      <SearchFilter
        label={translate('search')}
        source="title_contains"
        {...props}
        alwaysOn
      />

      <CategoryFilter
        source="category"
        label={translate('category')}
        {...props}
      />

      <ReferenceInput
        source="page"
        reference="page"
        label={translate('page')}
        filter={{
          folder: false,
          status: 'published',
          ...(identity?.user.domain ? { domain: domainId } : {}),
          ...(props.filterValues?.domain
            ? { domain: props.filterValues?.domain }
            : {}),
          _populate: pagePopulate.join(','),
        }}
        perPage={domainId ? -1 : 50}
        allowEmpty={true}
        helperText={false}
      >
        <AutocompleteInput optionText="title" helperText={false} />
      </ReferenceInput>

      <StatusFilter label={translate('status')} {...props} alwaysOn />
    </Filter>
  );
};

export default ImageFilter;
