import React from 'react';
import {
  useGetIdentity,
  SelectInput,
  ReferenceInput,
  useTranslate,
  Filter,
} from 'react-admin';
import { pagePopulate } from '../../populate';
import CategoryFilter from '../CategoryFilter';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';
import StatusFilter from '../StatusFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import ReferenceFilter from '../ReferenceFilter';
import { AutocompleteInput } from 'react-admin';

const PageFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      {identity?.user?.isAdmin && (
        <ReferenceFilter
          key="reference"
          label={translate('reference')}
          source="reference_contains"
          {...props}
        />
      )}

      {!props.hideSearch && (
        <SearchFilter
          label={translate('search')}
          source="title_contains"
          {...props}
          alwaysOn={isXSmall ? false : true}
        />
      )}

      <ReferenceInput
        source="parent"
        reference="page"
        label={translate('parent')}
        filter={{
          folder: true,
          status: 'published',
          ...(identity?.user.domain ? { domain: identity?.user.domain } : {}),
          ...(props.filterValues?.domain
            ? { domain: props.filterValues?.domain }
            : {}),
          _populate: pagePopulate.join(','),
        }}
        sort={{ field: 'title', order: 'ASC' }}
        perPage={props?.record?.domain ? -1 : 50}
        allowEmpty={true}
        helperText={false}
      >
        <AutocompleteInput optionText="title" helperText={false} />
      </ReferenceInput>

      <CategoryFilter
        source="category"
        label={translate('category')}
        {...props}
      />

      <SelectInput
        source="folder"
        label={translate('type')}
        choices={[
          { id: false, name: translate('page') },
          { id: true, name: translate('folder') },
        ]}
        allowEmpty={false}
        defaultValue={'published'}
        style={{ width: 250 }}
        helperText={false}
      />

      <StatusFilter label={translate('status')} {...props} alwaysOn />
    </Filter>
  );
};

export default PageFilter;
