import React from 'react';
import {
  useTranslate,
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

const TownShow = (props: any) => {
  const translate = useTranslate();

  return (
    <Show title={translate('towns')} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="uuid" />
        <TextField source="name" />
        <TextField source="adress" />
        <JsonField source="openingHours" />
        <TextField source="url" />
        <TextField source="phone" />
        <TextField source="email" />
        <ReferenceField
          source="city"
          reference="city"
          link="show"
          label={translate('city')}
        >
          <TextField source="name" />
        </ReferenceField>
        <JsonField source="geometry" />
      </SimpleShowLayout>
    </Show>
  );
};

export default TownShow;
