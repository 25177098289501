import React from 'react';
import { ImageField } from 'react-admin';
import {
  useTranslate,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
} from 'react-admin';
import MyPagination from '../Pagination';
import FFFFilter from './FFFFilter';

const FFFList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('schools')}
      filters={<FFFFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <ImageField source={'icon'} label={''} />
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" label={translate('name')} />
        <TextField source="url" label={translate('url')} />
        <ReferenceField
          source="department"
          reference="department"
          label={translate('department')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="city.id"
          reference="city"
          label={translate('city')}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default FFFList;
