import React, { useState } from 'react';
import { useTranslate, required } from 'react-admin';
import { TextInput } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { Button } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

export const Tab = ({ source }: { source: any }) => {
  const translate = useTranslate();
  const [displayConfiguration, setDisplayConfiguration] =
    useState<boolean>(false);

  const typeEnum = [
    'home',
    'page',
    'articles',
    'events',
    'notifications',
    'settings',
    'images',
    'domains',
    'menu',
    'favorites',
    'golfScoreCard',
    'basketRanking',
  ].map((key) => ({
    id: key,
    name: key,
  }));

  return (
    <div className="panel-group">
      <SelectInput
        label={translate('tabs.type')}
        source={source('type')}
        choices={typeEnum}
        allowEmpty={false}
        style={{ width: 250 }}
        helperText={false}
        validate={required()}
      />

      <p>
        <Button
          label={`${
            !displayConfiguration ? 'Afficher' : 'Masquer'
          } les options`}
          onClick={() => {
            setDisplayConfiguration(!displayConfiguration);
          }}
        />
      </p>

      {displayConfiguration && (
        <>
          <TextInput
            source={source('label')}
            label={translate('tabs.label')}
            helperText={false}
            fullWidth
          />
          <TextInput
            source={source('icon')}
            label={translate('tabs.icon')}
            helperText={false}
            fullWidth
          />
          <BooleanInput
            source={source('disabled')}
            label={translate('widgets.disabled')}
            helperText={false}
          />
          <BooleanInput
            source={source('lowDataMode')}
            label={translate('configuration.lowDataMode')}
            helperText={false}
            defaultValue={true}
          />
          <JsonInput
            source={source('params')}
            label={translate('configuration.params')}
          />
        </>
      )}
    </div>
  );
};
