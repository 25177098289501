import React from 'react';
import {
  useTranslate,
  Edit,
  useGetIdentity,
  TabbedForm,
  FormTab,
} from 'react-admin';
import AdressTab from '../AdressTab';
import EditToolbar from '../EditToolBar';
import MainTab from './MainTab';
import RootTab from './RootTab';
import DesignTab from './DesignTab';

const NotificationEdit = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <Edit title={translate('notifications')} {...props}>
      <TabbedForm
        redirect="list"
        toolbar={<EditToolbar hideDeletion={!identity?.user?.isAdmin} />}
      >
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" />
        </FormTab>

        {(identity?.user?.isAdmin ||
          identity?.user?.domain?.configuration?.enableMap) && (
          <FormTab label={translate('adress')}>
            <AdressTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default NotificationEdit;
