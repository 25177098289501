import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
} from 'react-admin';
import MyPagination from '../Pagination';
import SchoolFilter from './SchoolFilter';

const SchoolList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('schools')}
      filters={<SchoolFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" label={translate('name')} />
        <TextField source="adress" label={translate('adress')} />
        <TextField source="academy" label={translate('academy')} />
        <TextField source="secteur" label={translate('secteur')} />
        <ReferenceField
          source="city"
          reference="city"
          label={translate('city')}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default SchoolList;
