import React from 'react';
import { useTranslate, List, Datagrid, TextField } from 'react-admin';
import ChipField from '../ChipField';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import TitleField from '../TitleField';
import QrcodeFilter from './QrcodeFilter';

const QrcodeList = (props: any) => {
  const translate = useTranslate();

  return (
    <List
      title={translate('qrcodes')}
      pagination={<MyPagination />}
      filters={<QrcodeFilter />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="title" label={translate('title')} />
        <TitleField source="title" label={''} />

        <ChipField source={'url'} />
        <ChipField source={'redirect'} />
        <ImageField label={''} />
      </Datagrid>
    </List>
  );
};

export default QrcodeList;
