import React from 'react';
import { useTranslate, TextInput } from 'react-admin';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        source="name"
        label={translate('name')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="uuid"
        label={translate('uuid')}
        helperText={false}
        fullWidth
      />
    </>
  );
};

export default MainTab;
