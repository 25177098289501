import React from 'react';
import { FormTab, TabbedForm } from 'ra-ui-materialui';
import { useTranslate, Create, useGetIdentity } from 'react-admin';
import EditToolbar from '../EditToolBar';
import DesignTab from './DesignTab';
import MainTab from './MainTab';
import RssTab from './RssTab';
import PackTab from './PackTab';
import RootTab from './RootTab';
import SocialTab from './SocialTab';
import AdressTab from '../AdressTab';
import WpJsonTab from './WpJsonTab';

const DomainCreate = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <Create title={translate('domains')} {...props}>
      <TabbedForm toolbar={<EditToolbar />} redirect="list">
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" />
        </FormTab>
        <FormTab label={translate('adress')}>
          <AdressTab />
        </FormTab>
        <FormTab label={translate('design')}>
          <DesignTab />
        </FormTab>
        <FormTab label={translate('socials')}>
          <SocialTab />
        </FormTab>
        <FormTab label={translate('rss')}>
          <RssTab />
        </FormTab>
        {identity?.user?.isAdmin && (
          <FormTab label={translate('wpJson')}>
            <WpJsonTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('pack')}>
            <PackTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('admin')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};
export default DomainCreate;
