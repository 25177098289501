import { AutocompleteInput, SelectInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, TextInput, ReferenceInput } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import DomainFilter from '../DomainFilter';
import { domaineTypeEnum } from './DomainValidate';

const RootTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <DomainFilter source="parent" label={translate('rootDomain')} />

      <TextInput
        source="uuid"
        label={translate('uuid')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="slug"
        label={translate('slug')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="urlSlug"
        label={translate('urlSlug')}
        helperText={false}
        fullWidth
      />

      <JsonInput
        source="namespaces"
        label={translate('namespaces')}
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <SelectInput
        source="type"
        choices={domaineTypeEnum}
        allowEmpty={true}
        helperText={false}
      />
      <ReferenceInput
        source="school"
        reference="school"
        label={translate('school')}
        fullWidth
        allowEmpty={true}
        helperText={false}
      >
        <AutocompleteInput optionText="name" label={translate('name')} />
      </ReferenceInput>

      <TextInput
        source="configuration.privacyUrl"
        label={translate('configuration.privacyUrl')}
        helperText={'https://www.appliendirect.fr/gdpr'}
        fullWidth
      />

      <TextInput
        source="configuration.legalUrl"
        label={translate('configuration.legalUrl')}
        helperText={'https://www.appliendirect.fr/legal'}
        fullWidth
      />

      <TextInput
        source="configuration.contactEmail"
        label={translate('configuration.contactEmail')}
        helperText={'contact@appliendirect.fr'}
        fullWidth
      />

      <TextInput
        source="configuration.registerUrl"
        label={translate('configuration.registerUrl')}
        helperText={'https://www.appliendirect.fr/register'}
        fullWidth
      />

      <TextInput
        source="configuration.supportUrl"
        label={translate('configuration.supportUrl')}
        helperText={'https://www.appliendirect.fr/support'}
        fullWidth
      />

      <TextInput
        source="configuration.shareUrl"
        label={translate('configuration.shareUrl')}
        helperText={'https://www.appliendirect.fr/app/?id=golflaboulie'}
        fullWidth
      />

      <TextInput
        source="configuration.releasesUrl"
        label={translate('configuration.releasesUrl')}
        helperText={'https://www.appliendirect.fr/releases'}
        fullWidth
      />

      <TextInput
        source="configuration.kitCommunicationUrl"
        label={translate('configuration.kitCommunicationUrl')}
        fullWidth
      />

      <TextInput
        source="configuration.caniuseUrl"
        label={translate('configuration.caniuseUrl')}
        helperText={'https://www.appliendirect.fr/caniuse'}
        fullWidth
      />

      <TextInput
        source="configuration.appStoreId"
        label={'appStoreId'}
        helperText={'6447369699'}
        fullWidth
      />
      <TextInput
        source="configuration.playStoreId"
        label={'playStoreId'}
        helperText={'com.domainedunet.golflaboulie'}
        fullWidth
      />

      <TextInput
        source="configuration.fetch.image"
        label={translate('configuration.fetch.image')}
        helperText={false}
        fullWidth
      />

      <TextInput
        source="configuration.customCSS"
        label={translate('configuration.customCSS')}
        helperText={false}
        fullWidth
      />

      <TextInput
        source="configuration.customJS"
        label={translate('configuration.customJS')}
        helperText={false}
        fullWidth
      />

      <JsonInput
        source="configuration"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="geometry"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="hours"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </>
  );
};

export default RootTab;
