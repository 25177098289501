import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  useGetIdentity,
} from 'react-admin';
import ChipField from '../ChipField';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import DomainFilter from './DomainFilter';
import { domainPopulate } from '../../populate';
import { domaineTypeEnum } from './DomainValidate';
import { useMediaQuery, Theme } from '@material-ui/core';
import AccountDesactivated from '../AccountDesactivated';
import DomainField from '../DomainField';

const moment = require('moment');

const TypeField = ({ record }: any) => {
  let color, backgroundColor;

  if (!record) {
    return <></>;
  }

  if (record.type === 'town') {
    backgroundColor = '#55C8B7';
    color = '#fff';
  } else if (record.type === 'school') {
    backgroundColor = '#cb7299';
    color = '#fff';
  } else if (record.type === 'association') {
    backgroundColor = '#fe6567';
    color = '#fff';
  } else if (record.type === 'restaurant') {
    backgroundColor = '#616c9c';
    color = '#fff';
  } else if (record.type === 'golf') {
    backgroundColor = '#0096a1';
    color = '#fff';
  } else if (record.type === 'run') {
    backgroundColor = '#0096a1';
    color = '#fff';
  }

  const type = domaineTypeEnum.find((type) => type.id === record.type);

  return type && type.name ? (
    <ChipField
      record={record}
      label={type.name}
      backgroundColor={backgroundColor}
      color={color}
    />
  ) : (
    <></>
  );
};

const RequestReviewFrequency = ({ record }: any) => {
  let color = '#000',
    backgroundColor = '#E0E0E0';

  if (!record || record.pack !== 'vip') {
    return <></>;
  }

  return (
    <span
      style={{
        backgroundColor,
        color,
        display: 'flex',
        width: 25,
        height: 25,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 11,
      }}
    >
      {record.configuration?.requestReviewFrequency}
    </span>
  );
};

const RatingField = ({ record }: any) => {
  let color, backgroundColor;

  if (!record || record.pack !== 'vip') {
    return <></>;
  }

  if (record.configuration?.appStore?.rating > 4) {
    backgroundColor = 'green';
    color = '#fff';
  } else if (record.configuration?.appStore?.rating < 4) {
    backgroundColor = 'red';
    color = '#fff';
  } else {
    backgroundColor = 'orange';
    color = '#fff';
  }

  return (
    <span
      style={{
        backgroundColor,
        color,
        display: 'flex',
        width: 25,
        height: 25,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
      }}
    >
      {record.configuration?.appStore?.rating}
    </span>
  );
};

const ReviewsField = ({ record }: any) => {
  let color = '#000',
    backgroundColor = '#E0E0E0';

  if (!record || record.pack !== 'vip') {
    return <></>;
  }

  if (record.configuration?.appStore?.reviews > 50) {
    backgroundColor = 'green';
    color = '#fff';
  }

  return (
    <span
      style={{
        backgroundColor,
        color,
        display: 'flex',
        width: 25,
        height: 25,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 11,
      }}
    >
      {record.configuration?.appStore?.reviews}
    </span>
  );
};

const PackField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }

  let color, backgroundColor;
  if (record.pack === 'vip') {
    backgroundColor = `rgba(0, 148, 160,1)`;
    color = '#fff';
  } else if (record.pack === 'premium') {
    backgroundColor = `rgba(0, 148, 160,0.8)`;
    color = '#fff';
  } else if (record.pack === 'starter') {
    backgroundColor = `rgba(0, 148, 160,0.5)`;
    color = '#fff';
  }
  return record.pack ? (
    <ChipField
      record={record}
      label={record.pack}
      color={color}
      backgroundColor={backgroundColor}
    />
  ) : (
    <></>
  );
};

const ArrField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  const arr = `${record?.configuration?.pack?.arr} €HT`;
  return record?.configuration?.pack?.arr !== null &&
    record?.configuration?.pack?.arr !== undefined ? (
    <ChipField record={record} label={arr} />
  ) : (
    <></>
  );
};

const RenewalDateField = ({ record }: any) => {
  if (!record?.configuration?.pack?.renewalDate) {
    return <>/</>;
  }
  const renewalDate = moment(record?.configuration?.pack?.renewalDate);
  const daydiff = renewalDate.diff(moment(new Date()), 'days');
  const color = daydiff < 0 ? 'red' : daydiff < 30 ? 'orange' : 'auto';
  return (
    <span style={{ color: color }}>
      {moment(record?.configuration?.pack?.renewalDate).format('DD/MM/YYYY')}
    </span>
  );
};

const DomainList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const actions = { ...(!identity?.user?.isAdmin ? { actions: null } : {}) };
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return (
    <div>
      {identity?.user?.domain?.configuration?.disableAdmin && (
        <AccountDesactivated />
      )}
      <List
        title={translate('domains')}
        pagination={<MyPagination />}
        filter={{ _populate: domainPopulate.join(',') }}
        filterDefaultValues={{ status: 'published' }}
        filters={<DomainFilter />}
        perPage={10}
        exporter={false}
        bulkActionButtons={false}
        {...actions}
        {...props}
      >
        <Datagrid
          rowClick={
            !identity?.user?.domain?.configuration?.disableAdmin ? 'edit' : ''
          }
        >
          <ImageField source="icon" label={''} />
          {!isXSmall && <ImageField label={''} />}
          <TextField source="name" label={translate('name')} />

          {identity?.user?.isAdmin && (
            <DomainField source="parent" label={translate('domain')} />
          )}

          {identity?.user?.isAdmin && !isXSmall && <TypeField label={''} />}

          <PackField source={'pack'} />

          {identity?.user?.isAdmin && !isXSmall && (
            <RequestReviewFrequency
              label={'configuration.requestReviewFrequency'}
            />
          )}

          {(identity?.user?.domain?.pack === 'vip' ||
            (identity?.user?.isAdmin && !isXSmall)) && (
            <RatingField label={translate('configuration.appStore.rating')} />
          )}

          {(identity?.user?.domain?.pack === 'vip' ||
            (identity?.user?.isAdmin && !isXSmall)) && (
            <ReviewsField label={translate('configuration.appStore.reviews')} />
          )}

          <ReferenceField
            source="city.id"
            reference="city"
            label={translate('city')}
            link="none"
            sortBy="city"
            linkType={identity?.user?.isAdmin}
          >
            <TextField source="name" />
          </ReferenceField>

          <ArrField
            label={translate('configuration.pack.arr')}
            source={'configuration.pack.arr'}
          />
          <RenewalDateField
            label={translate('configuration.pack.renewalDate')}
          />
        </Datagrid>
      </List>
      {identity?.user.domain?.configuration?.appStoreId && (
        <p style={{ textAlign: 'center' }}>
          * Avis analysés quotidiennement -{' '}
          <a
            href={`https://apps.apple.com/fr/app/appliendirect/id${identity?.user.domain?.configuration?.appStoreId}`}
            target="_blank"
            rel="noreferrer"
          >
            Consulter votre fiche AppStore
          </a>
        </p>
      )}
    </div>
  );
};

export default DomainList;
