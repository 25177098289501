import React from 'react';
import { useTranslate, Edit, SimpleForm } from 'react-admin';
import EditToolbar from '../EditToolBar';
import MainTab from './MainTab';

const RoleEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit title={translate('roles')} {...props}>
      <SimpleForm redirect="list" toolbar={<EditToolbar />}>
        <MainTab />
      </SimpleForm>
    </Edit>
  );
};

export default RoleEdit;
