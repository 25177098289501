import React from 'react';
import { useTranslate, Filter } from 'react-admin';
import SearchFilter from '../SearchFilter';

const CountryFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn
      />
    </Filter>
  );
};

export default CountryFilter;
