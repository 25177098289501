import React from 'react';
import TrueIcon from '@material-ui/icons/RadioButtonChecked';
import FalseIcon from '@material-ui/icons/RadioButtonUnchecked';

const BooleanField = ({ record, source }: any) => {
  if (!record || !record) {
    return <></>;
  }
  const value = record[source];
  return value ? <TrueIcon style={{}} /> : <FalseIcon style={{}} />;
};

export default BooleanField;
