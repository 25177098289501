import { TextInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';
import CategoryInput from '../CategoryInput';
import DomainInput from '../DomainInput';
import StatusInput from '../StatusInput';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <DomainInput />
      <TextInput
        source="title"
        label={translate('title')}
        helperText={false}
        fullWidth
      />
      <CategoryInput domain={props.record?.domain} />
      <StatusInput publishDate={true} mode={props.mode} />
    </>
  );
};

export default MainTab;
