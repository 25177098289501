import React from 'react';
import { useTranslate, TextInput } from 'react-admin';

interface Props {
  label: string;
  source: string;
  alwaysOn?: boolean;
}

const ReferenceFilter = (props: Props) => {
  const translate = useTranslate();
  return (
    <TextInput
      label={props.label || translate('reference')}
      source={props.source}
      alwaysOn
      style={{ width: 125 }}
      helperText={false}
    />
  );
};

export default ReferenceFilter;
