import React from 'react';
import { useTranslate, TextInput } from 'react-admin';

const MainTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        source="title"
        label={translate('title')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="url"
        label={translate('url')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="darkColor"
        label={'darkColor'}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="lightColor"
        label={'lightColor'}
        helperText={false}
        fullWidth
      />
    </>
  );
};

export default MainTab;
