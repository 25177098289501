import React from 'react';

const AccountDesactivated = () => {
  return (
    <p>
      <strong>Votre compte a été désactivé.</strong>
      <br />
      Si vous souhaitez le réactiver,{' '}
      <a href="https://www.domainedunet.fr/support">
        merci de contactez notre équipe
      </a>
    </p>
  );
};

export default AccountDesactivated;
