import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  TextField,
  useGetIdentity,
  ReferenceField,
} from 'react-admin';
import ChipField from '../ChipField';
import MyPagination from '../Pagination';
import UserFilter from './UserFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import CategoryField from '../CategoryField';
import AccountDesactivated from '../AccountDesactivated';

const UserList = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('users')}
      pagination={<MyPagination />}
      perPage={10}
      filters={<UserFilter />}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <ReferenceField
            source="domain.id"
            reference="domain"
            label={translate('domain')}
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="email" label={translate('email')} />
        {!isXSmall && <CategoryField source="category" label={''} />}
        {identity?.user?.isAdmin && (
          <ReferenceField
            source="role.id"
            reference="role"
            label={translate('role')}
          >
            <ChipField source={'name'} />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default UserList;
