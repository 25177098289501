import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useGetIdentity } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const ImageField = ({ record, source = 'image' }: any) => {
  const { identity } = useGetIdentity();
  const classes = useStyles();
  if (!record) {
    return <></>;
  }
  const image =
    record?.[`${source}CDNUrl`] ||
    record?.[`${source}Url`] ||
    record?.[`${source}`];
  return image ? (
    <div
      className={classes.root}
      style={{ position: 'relative' }}
      onClick={async (e) => {
        if (identity?.user?.isAdmin) {
          e.stopPropagation();
          await navigator.clipboard.writeText(record?.[`${source}Url`]);
          alert(`L'adresse de l'image a bien été copiée.`);
        }
      }}
    >
      <Avatar src={image} className={classes.avatar} />
    </div>
  ) : null;
};

export default ImageField;
