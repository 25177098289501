import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import ImageTab, { IconTab } from '../ImageTab';
import {
  BooleanInput,
  RadioButtonGroupInput,
  TextInput,
} from 'ra-ui-materialui';
import ImageOrientationInput from '../ImageOrientationInput';
import ViewInput from '../ViewInput';

const DesignTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <>
      <TextInput
        source="configuration.mainColor"
        label={translate('configuration.mainColor')}
        helperText={'format hexadecimal #123456'}
        fullWidth
      />

      <TextInput
        source="configuration.accentColor"
        label={translate('configuration.accentColor')}
        helperText={'format hexadecimal #123456'}
        fullWidth
      />

      <TextInput
        source="configuration.iconBackgroundColor"
        label={translate('configuration.iconBackgroundColor')}
        helperText={'format hexadecimal #123456'}
        fullWidth
      />

      {identity?.user?.isAdmin && (
        <div>
          <RadioButtonGroupInput
            source="configuration.defaultTheme"
            initialValue={'light'}
            label={translate('configuration.defaultTheme')}
            choices={[
              { id: 'system', name: translate('system') },
              { id: 'light', name: translate('light') },
              { id: 'dark', name: translate('dark') },
            ]}
          />
          <BooleanInput
            source="configuration.settings.disableTheme"
            label={translate('configuration.settings.disableTheme')}
            helperText={false}
          />
          <TextInput
            source="configuration.placeholderUrl"
            label={translate('configuration.placeholderUrl')}
            helperText={false}
            fullWidth
          />

          <div className="panel-group">
            <p className="title">{translate('articles')}</p>
            <ViewInput
              source={'configuration.articles.view'}
              label={'Articles / View'}
            />
            <BooleanInput
              source="configuration.articles.fullscreen"
              label={'Articles / Fullscreen'}
              helperText={false}
              fullWidth
            />

            <ImageOrientationInput
              source={'configuration.articles.imageOrientation'}
              label={'Articles / ImageOrientation'}
            />
          </div>

          <div className="panel-group">
            <p className="title">{translate('events')}</p>
            <ViewInput
              source={'configuration.events.view'}
              label={'Notifications / View'}
            />
            <BooleanInput
              source="configuration.events.fullscreen"
              label={'Events / Fullscreen'}
              helperText={false}
            />

            <ImageOrientationInput
              source={'configuration.events.imageOrientation'}
              label={'Events / ImageOrientation'}
            />
          </div>

          <div className="panel-group">
            <p className="title">{translate('notifications')}</p>
            <ViewInput
              source={'configuration.notifications.view'}
              label={'Notifications / View'}
            />
            <BooleanInput
              source="configuration.notifications.fullscreen"
              label={'Notifications / Fullscreen'}
              helperText={false}
              fullWidth
            />

            <ImageOrientationInput
              source={'configuration.notifications.imageOrientation'}
              label={'Notifications / ImageOrientation'}
            />
          </div>

          <div className="panel-group">
            <p className="title">{translate('pages')}</p>
            <ViewInput
              source={'configuration.pages.view'}
              label={'Notifications / View'}
            />
            <BooleanInput
              source="configuration.pages.fullscreen"
              label={'Pages / Fullscreen'}
              helperText={false}
              fullWidth
            />

            <ImageOrientationInput
              source={'configuration.pages.imageOrientation'}
              label={'Pages / ImageOrientation'}
            />
          </div>
        </div>
      )}

      <BooleanInput
        source="configuration.hideName"
        label={translate('configuration.hideName')}
        helperText={false}
      />

      <BooleanInput
        source="configuration.homepage.hideImage"
        label={translate('configuration.homepage.hideImage')}
        helperText={false}
      />

      {identity?.user?.isAdmin && (
        <BooleanInput
          source="configuration.homepageDefaultWidgets"
          label={translate('configuration.homepageDefaultWidgets')}
          helperText={false}
        />
      )}

      {identity?.user?.isAdmin && (
        <BooleanInput
          source={'configuration.disableLocation'}
          label={translate('configuration.disableLocation')}
          helperText={false}
        />
      )}

      <div>
        <IconTab {...props} helperText={'Format 500x500'} />
        <ImageTab {...props} helperText={'Format 1500x1000'} />
      </div>
    </>
  );
};

export default DesignTab;
