import React from 'react';
import { useTranslate, BooleanInput, TextInput } from 'react-admin';
import { WidgetButton } from './WidgetButton';

export const WidgetTimer = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <TextInput
        source={source('title')}
        label={translate('widgets.title')}
        helperText={false}
        fullWidth
      />
      <BooleanInput
        source={source('hideTitle')}
        label={translate('widgets.hideTitle')}
        helperText={false}
      />
      <TextInput
        source={source('date')}
        label={translate('widgets.date')}
        helperText={false}
        fullWidth
      />
      <BooleanInput
        source={source('hideDate')}
        label={translate('widgets.hideDate')}
        helperText={false}
      />
      <div className="panel-group">
        <p>
          <strong>Bouton d'action</strong>
        </p>
        <WidgetButton source={source} />
      </div>
    </div>
  );
};
