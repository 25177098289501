import React from 'react';
import LinkIcon from '@material-ui/icons/Link';

const UrlField = ({ record, source }: any) => {
  return record && record[source] ? (
    <a key={record[source]} href={record[source]} style={{ color: '#000' }}>
      <LinkIcon />
    </a>
  ) : (
    <></>
  );
};

export default UrlField;
