import React from 'react';
import { useTranslate, Filter, ReferenceInput, SelectInput } from 'react-admin';
import SearchFilter from '../SearchFilter';

const DepartmentFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn
      />
      <ReferenceInput
        label={translate('country')}
        source="country"
        reference="country"
        perPage={10}
        helperText={false}
      >
        <SelectInput optionText="name" helperText={false} />
      </ReferenceInput>
      <ReferenceInput
        label={translate('region')}
        source="region"
        reference="region"
        perPage={10}
        helperText={false}
      >
        <SelectInput optionText="name" helperText={false} />
      </ReferenceInput>
    </Filter>
  );
};

export default DepartmentFilter;
