import React from 'react';
import { JsonInput } from 'react-admin-json-view';
import TargetInput from '../TargetInput';

const RootTab = (props: any) => {
  return (
    <>
      <TargetInput />

      <JsonInput
        source="configuration.params"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="configuration"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </>
  );
};

export default RootTab;
