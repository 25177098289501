import React, { useState } from 'react';
import { NumberInput, TextInput, useTranslate } from 'react-admin';
import { useGetOne } from 'ra-core';
import Barcode from 'react-barcode';

interface Props {
  id: number;
  record?: any;
}

const LoyaltyTab = (props: Props) => {
  const translate = useTranslate();
  const { data } = useGetOne('domain', props.id || 0);
  const [password, setPassword] = useState<string>(
    data?.configuration?.loyalty?.password || props.id
  );

  return (
    <>
      <TextInput
        source="configuration.loyalty.title"
        label={translate('loyalty.title')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="configuration.loyalty.description"
        label={translate('loyalty.description')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="configuration.loyalty.password"
        label={translate('loyalty.password')}
        helperText={`Ex: ${props.record?.id}`}
        fullWidth
        onChange={(e: any) => {
          setPassword(e.target.value);
        }}
      />
      <Barcode value={password} displayValue={false} />
      <NumberInput
        source="configuration.loyalty.points"
        label={translate('loyalty.points')}
        helperText={'Ex: 10'}
        fullWidth
      />
    </>
  );
};

export default LoyaltyTab;
