import React from 'react';
import {
  ReferenceField,
  useTranslate,
  List,
  Datagrid,
  NumberField,
  TextField,
} from 'react-admin';
import MyPagination from '../Pagination';
import CityFilter from './CityFilter';

const CityList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      title={translate('cities')}
      filters={<CityFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" />
        <ReferenceField
          source="department"
          reference="department"
          label={translate('department')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="region"
          reference="region"
          label={translate('region')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="country"
          reference="country"
          label={translate('country')}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="postal" label={translate('postal')} />
        <TextField source="latitude" />
        <TextField source="longitude" />
      </Datagrid>
    </List>
  );
};

export default CityList;
