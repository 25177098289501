import {
  BooleanInput,
  SelectInput,
  DateInput,
  NumberInput,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';
import { domainePackEnum } from './DomainValidate';

const PackTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <SelectInput
        source="pack"
        choices={domainePackEnum}
        allowEmpty={true}
        helperText={false}
      />
      <TextInput
        source="configuration.appStoreVersion"
        label={translate('configuration.appStoreVersion')}
        helperText={'1.17.1'}
        fullWidth
      />
      <BooleanInput
        source="configuration.enableDebug"
        label={translate('configuration.enableDebug')}
        helperText={false}
      />
      <div>
        <NumberInput
          source="configuration.requestReviewFrequency"
          label={translate('configuration.requestReviewFrequency')}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        />
      </div>
      <div>
        <NumberInput
          source="configuration.hubspotId"
          label={translate('configuration.hubspotId')}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        />
      </div>
      <div>
        <DateInput
          source="configuration.pack.renewalDate"
          label={translate('configuration.pack.renewalDate')}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        />
      </div>
      <NumberInput
        source="configuration.pack.arr"
        label={translate('configuration.pack.arr')}
        allowEmpty={true}
        style={{ width: 250 }}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableAdmin"
        label={translate('configuration.disableAdmin')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disablePage"
        label={translate('configuration.disablePage')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableArticle"
        label={translate('configuration.disableArticle')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableNotification"
        label={translate('configuration.disableNotification')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableEvent"
        label={translate('configuration.disableEvent')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableCategory"
        label={translate('configuration.disableCategory')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableUser"
        label={translate('configuration.disableUser')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableDevice"
        label={translate('configuration.disableDevice')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableWebView"
        label={translate('configuration.disableWebView')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableSharing"
        label={translate('configuration.disableSharing')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableWeather"
        label={translate('configuration.disableWeather')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableHours"
        label={translate('configuration.disableHours')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.enableOpenInBrowser"
        label={translate('configuration.enableOpenInBrowser')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.enableLoyalty"
        label={translate('configuration.enableLoyalty')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.enableHours"
        label={translate('configuration.enableHours')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.enableMap"
        label={translate('configuration.enableMap')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.disableEventFilters"
        label={translate('configuration.disableEventFilters')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.enableAPI"
        label={translate('configuration.enableAPI')}
        helperText={false}
      />
      <BooleanInput
        source="configuration.events.notify"
        label={translate('configuration.events.notify', {
          days: process.env.REACT_APP_EVENT_NOTIFY_DAY_BEFORE || 3,
        })}
        helperText={false}
      />
      <BooleanInput
        source="configuration.archive.notFound"
        label={translate('configuration.archive.notFound')}
        helperText={false}
      />
    </>
  );
};

export default PackTab;
