import { NumberField } from 'ra-ui-materialui';
import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  TextField,
  useGetIdentity,
} from 'react-admin';
import BooleanField from '../BooleanField';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import StatusField from '../StatusField';
import CategoryFilter from './CategoryFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import { categoryPopulate } from '../../populate';
import DomainField from '../DomainField';
import AccountDesactivated from '../AccountDesactivated';
import { DateField } from 'react-admin';

const CategoryList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('categories')}
      pagination={<MyPagination />}
      perPage={10}
      filter={{ _populate: categoryPopulate.join(',') }}
      filters={<CategoryFilter {...props} />}
      filterDefaultValues={{ status: 'published' }}
      exporter={false}
      sort={{ field: 'created_at', order: 'ASC' }}
      bulkActionButtons={identity?.user?.isAdmin}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        <ImageField source="icon" label={''} />
        <TextField source="name" label={translate('name')} />
        {!isXSmall && (
          <BooleanField source="hiddable" label={translate('hiddable_list')} />
        )}
        {!isXSmall && (
          <NumberField
            source="sort"
            label={translate('sort_list')}
            style={{ width: 250 }}
          />
        )}
        <StatusField label={translate('status')} />
        {identity?.user?.isAdmin && (
          <DateField
            source="created_at"
            showTime={true}
            label={translate('created_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default CategoryList;
