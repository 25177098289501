import React from 'react';
import { useTranslate } from 'react-admin';
import ChipField from './ChipField';

const StatusField = ({ record }: any) => {
  const translate = useTranslate();
  let color, backgroundColor;

  if (!record) {
    return <></>;
  }

  if (record?.status === 'draft') {
    backgroundColor = '#879092';
    color = '#000';
  } else if (record?.status === 'scheduled') {
    backgroundColor = `rgba(0, 148, 160,0.5)`;
    color = '#fff';
  } else if (record?.status === 'published') {
    backgroundColor = `rgba(0, 148, 160,1)`;
    color = '#fff';
  } else if (record?.status === 'archive') {
    backgroundColor = '#FF6169';
    color = '#fff';
  }

  if (!color) {
    return <></>;
  }

  return (
    record && (
      <ChipField
        record={record}
        color={color}
        backgroundColor={backgroundColor}
        label={translate(record.status)}
      />
    )
  );
};

export default StatusField;
