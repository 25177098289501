import React from 'react';
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';
import { WidgetButton } from './WidgetButton';

export const WidgetButtons = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <ArrayInput
        source={source('buttons')}
        label={translate('widgets.buttons')}
      >
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }: { getSource: any }) => {
              return <WidgetButton source={getSource} />;
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};
