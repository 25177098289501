import { BooleanInput, TextInput } from 'ra-ui-materialui';
import React from 'react';
import {
  NumberField,
  useGetIdentity,
  TextField,
  useTranslate,
  Edit,
  SimpleForm,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import DomainInput from '../DomainInput';
import EditToolbar from '../EditToolBar';
import DeviceActions from './DeviceActions';
import { JsonInput } from 'react-admin-json-view';

const DeviceEdit = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <Edit title={translate('devices')} actions={<DeviceActions />} {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<EditToolbar hideDeletion={!identity?.user?.isAdmin} />}
      >
        <NumberField source="id" label={translate('id')} />
        <DomainInput />
        <TextField source="name" label={translate('name')} />
        <TextInput
          source="configuration.user"
          label={translate('user')}
          helperText={false}
        />
        <TextField source="appVersion" label={translate('appVersion')} />
        <TextField source="platform" label={translate('platform')} />
        <TextField source="theme" label={translate('theme')} />
        <BooleanInput
          source="notification"
          label={translate('notification')}
          helperText={false}
        />
        <TextInput
          source="token"
          label={translate('token')}
          helperText={false}
          fullWidth
        />
        <TextField source="uuid" label={translate('uuid')} />
        <TextField source="created_at" label={translate('created_at')} />
        <TextField source="updated_at" label={translate('updated_at')} />
        <JsonField source="settings" />
        <JsonField source="configuration" />
        <JsonInput
          source="configuration"
          reactJsonOptions={{
            name: null,
            collapsed: false,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default DeviceEdit;
