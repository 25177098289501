import React from 'react';
import {
  useTranslate,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import SearchFilter from '../SearchFilter';

const FFFFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchFilter
        label={translate('search')}
        source="name_contains"
        {...props}
        alwaysOn
      />
      <ReferenceInput
        label={translate('department')}
        source="department"
        reference="department"
        perPage={50}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput
          optionText={(record: any) => {
            return record?.id ? `${record?.name}` : '';
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label={translate('city')}
        source="city"
        reference="city"
        perPage={50}
        helperText={false}
        fullWidth
      >
        <AutocompleteInput
          optionText={(record: any) => {
            return record?.id ? `${record?.name}` : '';
          }}
        />
      </ReferenceInput>
    </Filter>
  );
};

export default FFFFilter;
