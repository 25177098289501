import { useTranslate } from 'react-admin';

export const useWidgets = () => {
  const translate = useTranslate();

  const typeEnum = [
    { id: 'pages', name: translate('widgets.pages') },
    { id: 'events', name: translate('widgets.events') },
    { id: 'articles', name: translate('widgets.articles') },
    { id: 'notifications', name: translate('widgets.notifications') },
    { id: 'news', name: translate('widgets.news') },
    { id: 'html', name: translate('widgets.html') },
    { id: 'buttons', name: translate('widgets.buttons') },
    { id: 'button', name: translate('widgets.button') },
    { id: 'timer', name: translate('widgets.timer') },
    { id: 'domains', name: translate('widgets.domains') },
    { id: 'hours', name: translate('widgets.hours') },
    { id: 'propulsed', name: translate('widgets.propulsed') },
    { id: 'loyalty', name: translate('widgets.loyalty') },
    { id: 'weather', name: translate('widgets.weather') },
    { id: 'golfScoreCard', name: translate('widgets.golfScoreCard') },
  ];

  const imageOrientationEnum = [
    { id: 'portrait', name: translate('portrait') },
    { id: 'landscape', name: translate('landscape') },
    { id: 'square', name: translate('square') },
    { id: 'none', name: translate('none') },
  ];

  const viewEnum = [
    { id: 'list', name: translate('list') },
    { id: 'brick', name: translate('brick') },
    { id: 'slide', name: translate('slide') },
  ];

  const paginationPositionEnum = [
    { id: 'top', name: translate('configuration.pagination.position.top') },
    {
      id: 'bottom',
      name: translate('configuration.pagination.position.bottom'),
    },
  ];

  return {
    typeEnum,
    imageOrientationEnum,
    viewEnum,
    paginationPositionEnum,
  };
};
