import React from 'react';

import { useTranslate, TextInput } from 'react-admin';

const OverlayInput = (props: any) => {
  const translate = useTranslate();

  return (
    <>
      {/*
      <SelectInput
        label={translate('status')}
        choices={[
          {
            id: 'play-circle-outline',
            name: translate('overlay.models.video'),
          },
          {
            id: 'play-circle-outline',
            name: translate('overlay.models.photo'),
          },
          {
            id: 'eye-circle',
            name: translate('overlay.models.newspaper'),
          },
          {
            id: 'plus-circle',
            name: translate('overlay.models.plus'),
          },
        ]}
        allowEmpty={false}
        initialValue={undefined}
        onChange={(e: any) => {
          setCurrentIcon(e.target.value);
          console.log(props.record);
          //setValue('configuration.overlay.icon', e.target.value);
        }}
        style={{ width: 250 }}
        helperText={false}
      />
      */}
      <TextInput
        source="configuration.overlay.icon"
        label={translate('overlay.icon')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="configuration.overlay.label"
        label={translate('overlay.label')}
        helperText={false}
        fullWidth
      />
    </>
  );
};

export default OverlayInput;
