import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
  logo: {
    width: 'auto',
    height: 20,
    marginRight: 10,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 30,
  },
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
});
