import React from 'react';

const TitleField = ({ record }: any) => {
  const maxLength = 75;
  const recordLength = record?.title?.length;
  return record ? (
    <span>
      {recordLength > maxLength
        ? `${record.title.substr(0, maxLength)}...`
        : record.title}
    </span>
  ) : (
    <></>
  );
};

export default TitleField;
