import React from 'react';
import { useTranslate, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {},
  select: {},
});

interface Props {
  label?: string;
  source?: string;
  publishDate?: boolean;
  publishDateTime?: boolean;
  mode?: 'create' | 'edit';
  limited?: boolean;
}

const ViewInput = (props: Props) => {
  const translate = useTranslate();
  let classes = useStyles();

  const viewEnum = [
    { id: 'list', name: translate('list') },
    { id: 'brick', name: translate('brick') },
    { id: 'slide', name: translate('slide') },
    { id: 'card', name: translate('card') },
  ];

  return (
    <SelectInput
      label={props.label || translate('configuration.view')}
      source={props.source || 'configuration.view'}
      choices={viewEnum}
      allowEmpty={true}
      className={classes.select}
      style={{ width: 250 }}
      helperText={false}
    />
  );
};

export default ViewInput;
