import React from 'react';
import {
  useTranslate,
  TabbedForm,
  Edit,
  FormTab,
  useGetIdentity,
  useGetOne,
} from 'react-admin';
import EditToolbar from '../EditToolBar';
import RootTab from './RootTab';
import DesignTab from './DesignTab';
import MainTab from './MainTab';
import PackTab from './PackTab';
import RssTab from './RssTab';
import SocialTab from './SocialTab';
import AdressTab from '../AdressTab';
import WidgetTab from './WidgetTab';
import HoursTab from './HoursTab';
import LoyaltyTab from './LoyaltyTab';
import WpJsonTab from './WpJsonTab';
import TabsTab from './TabsTab';

const DomainEdit = (props: any, record: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { data } = useGetOne('domain', props.id || 0);
  return (
    <Edit title={translate('domains')} {...props}>
      <TabbedForm toolbar={<EditToolbar hideDeletion={true} />} redirect="list">
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" />
        </FormTab>
        <FormTab label={translate('adress')}>
          <AdressTab />
        </FormTab>
        {(data?.type === 'restaurant' ||
          data?.type === 'town' ||
          data?.hours ||
          data?.configuration?.enableHours) && (
          <FormTab label={translate('hours.label')}>
            <HoursTab />
          </FormTab>
        )}
        {(data?.type === 'restaurant' ||
          data?.configuration?.enableLoyalty ||
          data?.configuration?.loyalty) && (
          <FormTab label={translate('loyalty.label')}>
            <LoyaltyTab {...props} />
          </FormTab>
        )}
        <FormTab label={translate('design')}>
          <DesignTab {...props} />
        </FormTab>
        <FormTab label={translate('socials')}>
          <SocialTab />
        </FormTab>
        <FormTab label={translate('rss')}>
          <RssTab />
        </FormTab>
        {identity?.user?.isAdmin && (
          <FormTab label={translate('wpjson')}>
            <WpJsonTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('widget')}>
            <WidgetTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('tabs.title')}>
            <TabsTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('pack')}>
            <PackTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};
export default DomainEdit;
