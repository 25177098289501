import { TextInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import CategoryInput from '../CategoryInput';
import DomainInput from '../DomainInput';
import ImageTab from '../ImageTab';
import StatusInput from '../StatusInput';
import { notificationValidateTitle } from './NotificationValidate';
import RichTextInput from 'ra-input-rich-text';
import { validateUrl } from '../Validate';
import { BooleanInput } from 'ra-ui-materialui';
import ActionsTab from '../ActionsTab';

const MainTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <>
      <DomainInput />
      <TextInput
        source="title"
        label={translate('title')}
        validate={notificationValidateTitle}
        helperText={false}
        fullWidth
      />
      {identity?.user?.isAdmin && (
        <TextInput
          source="body"
          label={translate('body')}
          helperText={false}
          fullWidth
        />
      )}
      <ImageTab {...props} />

      <TextInput
        source="reference"
        label={translate('reference')}
        validate={[validateUrl(identity?.user?.isAdmin)]}
        helperText={false}
        fullWidth
      />
      {(identity?.user?.isAdmin ||
        identity?.user?.domain?.configuration?.enableOpenInBrowser) && (
        <BooleanInput
          source="openInBrowser"
          label={translate('openInBrowser')}
          helperText={false}
          fullWidth
        />
      )}
      <RichTextInput source="content" label={''} />
      <ActionsTab />
      <CategoryInput domain={props.record?.domain} />
      <StatusInput publishDateTime={true} mode={props.mode} />
    </>
  );
};

export default MainTab;
