import { NumberInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';

export interface Geometry {
  latitude?: number;
  latitudeDelta?: number;
  longitude?: number;
  longitudeDelta?: number;
}

const LocationInput = ({
  latitude,
  longitude,
  latitudeDelta,
  longitudeDelta,
  defaultLatitude,
  defaultLongitude,
  defaultLatitudeDelta,
  defaultLongitudeDelta,
}: {
  latitude: string;
  longitude: string;
  latitudeDelta: string;
  longitudeDelta: string;
  defaultLatitude?: number;
  defaultLongitude?: number;
  defaultLatitudeDelta?: number;
  defaultLongitudeDelta?: number;
}) => {
  const translate = useTranslate();

  return (
    <>
      {latitude && (
        <NumberInput
          source={latitude}
          label={translate('latitude')}
          defaultValue={defaultLatitude}
          helperText={false}
          fullWidth
        />
      )}
      {longitude && (
        <NumberInput
          source={longitude}
          label={translate('longitude')}
          defaultValue={defaultLongitude}
          helperText={false}
          fullWidth
        />
      )}
      {latitudeDelta && (
        <NumberInput
          source={latitudeDelta}
          label={translate('latitudeDelta')}
          defaultValue={defaultLatitudeDelta}
          helperText={false}
          fullWidth
        />
      )}
      {longitudeDelta && (
        <NumberInput
          source={longitudeDelta}
          label={translate('longitudeDelta')}
          defaultValue={defaultLongitudeDelta}
          helperText={false}
          fullWidth
        />
      )}
    </>
  );
};

export default LocationInput;
