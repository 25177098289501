import React from 'react';
import {
  useTranslate,
  List,
  Datagrid,
  TextField,
  useGetIdentity,
  DateField,
} from 'react-admin';
import ChipField from '../ChipField';
import MyPagination from '../Pagination';
import DeviceFilter from './DeviceFilter';
import TrueIcon from '@material-ui/icons/RadioButtonChecked';
import FalseIcon from '@material-ui/icons/RadioButtonUnchecked';
import StarIcon from '@material-ui/icons/Stars';
import DomainField from '../DomainField';
import { useMediaQuery, Theme } from '@material-ui/core';
import AccountDesactivated from '../AccountDesactivated';

const moment = require('moment');

const IsNewField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  const createdDate = moment(record.created_at).format('YYYY-MM-DD HH:mm');
  const updatedDate = moment(record.updated_at).format('YYYY-MM-DD HH:mm');
  return createdDate === updatedDate ? (
    <ChipField
      record={record}
      label={'Nouveau'}
      backgroundColor={`rgba(0, 148, 160,1)`}
      color="#fff"
    />
  ) : (
    <></>
  );
};

const IsDevelopmentField = ({ record }: any) => {
  if (!record || !record.configuration) {
    return <></>;
  }
  return record.configuration?.mode === 'development' ? <StarIcon /> : <></>;
};

const HasNotification = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  return record.notification ? <TrueIcon /> : <FalseIcon />;
};

const HasToken = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  return record.token ? <TrueIcon /> : <FalseIcon />;
};

const PlatformField = ({ record }: any) => {
  if (!record) {
    return <></>;
  }
  const label = record.platform === 'ios' ? 'apple' : 'android';
  const backgroundColor = record.platform === 'ios' ? `#55C8B7` : `#fe6567`;
  return (
    <ChipField
      record={record}
      label={label}
      color={'#fff'}
      backgroundColor={backgroundColor}
    />
  );
};

const DeviceList = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('devices')}
      filters={<DeviceFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={identity?.user?.isAdmin}
    >
      <Datagrid rowClick={identity?.user?.isAdmin ? 'edit' : null}>
        {identity?.user?.isAdmin && (
          <DomainField source="rootDomain" label={translate('rootDomain')} />
        )}
        {(!isXSmall || identity?.user?.isAdmin) && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        <PlatformField label={translate('platform')} />
        {identity?.user?.isAdmin && (
          <IsDevelopmentField
            source="configuration.mode"
            label={translate('development_short')}
          />
        )}
        <DateField
          source="created_at"
          label={translate('created_at')}
          showTime={!isXSmall}
        />
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
        {identity?.user?.isAdmin && <IsNewField />}
        {!isXSmall && (
          <HasNotification
            source="notification"
            label={translate('notification')}
          />
        )}
        {identity?.user?.isAdmin && (
          <HasToken source="token" label={translate('token')} />
        )}
        <TextField source="appVersion" label={translate('appVersion')} />

        {identity?.user?.isAdmin && (
          <TextField source="uuid" label={translate('uuid')} />
        )}
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default DeviceList;
