import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import CategoryInput from '../CategoryInput';
import DomainInput from '../DomainInput';
import StatusInput from '../StatusInput';
import { eventValidateDescription, eventValidateTitle } from './EventValidate';

const NotifyInput = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  return (
    <BooleanInput
      source="notification"
      label={translate('configuration.events.notify', {
        days: process.env.REACT_APP_EVENT_NOTIFY_DAY_BEFORE || 3,
      })}
      format={(value: any) =>
        value === true ||
        (identity?.user?.domain?.configuration?.events?.notify &&
          value === null)
      }
      helperText={false}
    />
  );
};

const MainTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <>
      <DomainInput />
      <TextInput
        source="title"
        label={translate('title')}
        validate={eventValidateTitle}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="description"
        label={translate('subtitle')}
        fullWidth
        validate={eventValidateDescription}
        helperText={false}
      />
      <CategoryInput domain={props.record?.domain} />

      {identity?.user?.isAdmin && (
        <>
          <TextInput
            source="adress"
            label={translate('adress')}
            helperText={false}
            fullWidth
          />
          <ReferenceInput
            source="city"
            reference="city"
            label={translate('city')}
            fullWidth
            allowEmpty={true}
            perPage={50}
            helperText={false}
          >
            <AutocompleteInput
              optionText={(record: any) => {
                return record?.id ? `${record?.name}` : '';
              }}
            />
          </ReferenceInput>
        </>
      )}

      <StatusInput publishDate={true} mode={props.mode} />
      <NotifyInput
        record={props.record}
        label={translate('configuration.events.notify_short', {
          days: process.env.REACT_APP_EVENT_NOTIFY_DAY_BEFORE || 3,
        })}
      />
    </>
  );
};

export default MainTab;
