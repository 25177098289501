import { DateField } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, List, Datagrid, useGetIdentity } from 'react-admin';
import ImageField from '../ImageField';
import MyPagination from '../Pagination';
import StatusField from '../StatusField';
import ArticleFilter from './ArticleFilter';
import { useMediaQuery, Theme } from '@material-ui/core';
import CategoryField from '../CategoryField';
import UrlField from '../UrlField';
import { articlePopulate } from '../../populate';
import DomainField from '../DomainField';
import TitleField from '../TitleField';
import { CloneButton } from '../CloneButton';
import AccountDesactivated from '../AccountDesactivated';

const ArticleList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return !identity?.user?.domain?.configuration?.disableAdmin ? (
    <List
      title={translate('articles')}
      pagination={<MyPagination />}
      perPage={10}
      filter={{ _populate: articlePopulate.join(',') }}
      filters={<ArticleFilter />}
      filterDefaultValues={{ status: 'published' }}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={identity?.user?.isAdmin}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        {identity?.user?.isAdmin && (
          <DomainField source="domain" label={translate('domain')} />
        )}
        <ImageField label={translate('image')} />
        <TitleField source="title" label={''} />
        {!isXSmall && <CategoryField source="category" label={''} />}
        {!isXSmall && <UrlField source="reference" label="" />}
        {!isXSmall && (
          <StatusField source="status" label={translate('status')} />
        )}
        {!isXSmall && (
          <DateField
            source="publish_at"
            showTime={true}
            label={translate('publish_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="created_at"
            showTime={true}
            label={translate('created_at')}
          />
        )}
        {identity?.user?.isAdmin && (
          <DateField
            source="updated_at"
            showTime={true}
            label={translate('updated_at')}
          />
        )}
        <CloneButton />
      </Datagrid>
    </List>
  ) : (
    <AccountDesactivated />
  );
};

export default ArticleList;
