import { BooleanInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, TextInput, useGetIdentity } from 'react-admin';

const RssTab = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  return (
    <>
      {!identity?.user?.domain?.configuration?.disableArticle && (
        <TextInput
          source="configuration.rss.article"
          label={translate('article')}
          helperText={false}
          fullWidth
        />
      )}

      {!identity?.user?.domain?.configuration?.disableEvent && (
        <TextInput
          source="configuration.rss.event"
          label={translate('event')}
          helperText={false}
          fullWidth
        />
      )}

      {!identity?.user?.domain?.configuration?.disableNotification && (
        <TextInput
          source="configuration.rss.notification"
          label={translate('notification')}
          helperText={false}
          fullWidth
        />
      )}

      <BooleanInput
        source="configuration.rss.disabled"
        label={translate('configuration.rss.disabled')}
        helperText={false}
      />
    </>
  );
};

export default RssTab;
