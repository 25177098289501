import React from 'react';
import {
  useGetIdentity,
  SimpleFormIterator,
  ArrayInput,
  useTranslate,
  FormDataConsumer,
} from 'react-admin';
import { Widget } from './Widgets/Widget';

const WidgetDetails = ({ configuration }: any) => {
  const widgets = configuration?.homepage?.widgets;
  if (widgets && widgets.length) {
    const details = widgets.map((widget: any) => {
      switch (widget.type) {
        case 'pages':
          return {
            ...widget,
            html: (
              <div>
                <p>
                  Paramètres :{' '}
                  {JSON.stringify({
                    pageId: widget.pages,
                    folder: widget.folder,
                    limit: widget.limit,
                    showPinned: widget.showPinned,
                    showFavorite: widget.showFavorite,
                  })}
                </p>
              </div>
            ),
          };
        case 'events':
          return {
            ...widget,
            html: (
              <div>
                <p>
                  Paramètres :{' '}
                  {JSON.stringify({
                    limit: widget.limit,
                  })}
                </p>
              </div>
            ),
          };
        case 'news':
          return {
            ...widget,
            html: (
              <div>
                <p>
                  Paramètres :{' '}
                  {JSON.stringify({
                    limit: widget.limit,
                  })}
                </p>
              </div>
            ),
          };
        case 'html':
          return {
            ...widget,
            html: <code>{widget.content}</code>,
          };
        case 'buttons':
          return {
            ...widget,
            html: (
              <div>
                <p>
                  Paramètres :{' '}
                  {JSON.stringify({
                    title: widget.buttons,
                  })}
                </p>
              </div>
            ),
          };
        case 'button':
          return {
            ...widget,
            html: (
              <div>
                <p>
                  Paramètres :{' '}
                  {JSON.stringify({
                    title: widget.title,
                    target: widget.target,
                    params: widget.params,
                  })}
                </p>
              </div>
            ),
          };
        case 'hours':
        case 'loyalty':
        default:
          return widget;
      }
    });
    return details.map((widget: any, index: number) => {
      return (
        <div key={`widget${index}`}>
          <p>Type : {widget.type}</p>
          <p>
            statut :{' '}
            <span style={{ color: !widget.disabled ? 'green' : 'red' }}>
              {!widget.disabled ? 'Actif' : 'Inactif'}
            </span>
          </p>
          <p>
            <strong>{widget.title || 'Pas de titre'}</strong>
          </p>
          {widget.html}

          <hr />
        </div>
      );
    });
  } else return <></>;
};

const WidgetTab = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  return (
    <>
      {!identity?.user?.isAdmin && (
        <WidgetDetails configuration={props?.record?.configuration} />
      )}
      {identity?.user?.isAdmin && (
        <ArrayInput
          source="configuration.homepage.widgets"
          label={translate('widgets.title')}
        >
          <SimpleFormIterator>
            <FormDataConsumer>
              {({
                scopedFormData,
                getSource,
              }: {
                scopedFormData: any;
                getSource: any;
              }) => {
                return (
                  <Widget
                    type={scopedFormData?.type}
                    source={getSource}
                    record={props.record}
                  />
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </>
  );
};

export default WidgetTab;
