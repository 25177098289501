import React from 'react';
import { AppBar, UserMenu, useGetIdentity } from 'react-admin';
import { useStyles } from '../styles';
import { useMediaQuery, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/People';
const moment = require('moment');

const CustomIcon = () => {
  const { identity } = useGetIdentity();
  const useStyles = makeStyles({
    icon: {
      width: 20,
      height: 20,
      borderRadius: 5,
    },
  });
  const classes = useStyles();
  return (
    <>
      {identity?.user?.domain?.icon ? (
        <img
          alt={identity?.user.domain.name}
          src={identity?.user.domain.icon}
          className={classes.icon}
        />
      ) : (
        <UserIcon className={classes.icon} />
      )}
    </>
  );
};

const CustomUserMenu = (props: any) => {
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <>
      {!isXSmall && identity?.user && (
        <span style={{ marginLeft: 10 }}>{identity?.user.email}</span>
      )}
      <UserMenu {...props} icon={<CustomIcon />}></UserMenu>
    </>
  );
};

const CustomAppBar = (props: any) => {
  const { identity } = useGetIdentity();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const renewalDate = moment(
    identity?.user?.domain?.configuration?.pack?.renewalDate
  );
  const today = moment(new Date());
  const pricing = identity?.user?.domain?.configuration?.pack?.arr;
  const dayDiff = renewalDate.diff(today, 'days');

  return (
    <>
      <AppBar {...props} userMenu={<CustomUserMenu />}>
        <Typography color="inherit" id="react-admin-title" />
        <span className={classes.spacer} />
      </AppBar>

      <div className="subAppBar">
        <p>
          <a href="https://www.appliendirect.fr/releases">Nouveautés</a>
          <a href="https://www.appliendirect.fr/caniuse">
            Proposer une fonctionnalité
          </a>
          {!isXSmall && (
            <a href="https://www.appliendirect.fr/ticket/">
              Contacter notre équipe
            </a>
          )}
        </p>
        <p>
          <a href="https://www.appliendirect.fr/interview/" className="focus">
            Votre avis nous intéresse !
          </a>
        </p>
        <p>
          {identity?.user?.domain?.configuration?.kitCommunicationUrl && (
            <a
              href={identity?.user?.domain?.configuration?.kitCommunicationUrl}
              target="_blank"
              rel="noreferrer"
              className="focus"
            >
              Kit de communication
            </a>
          )}
        </p>
      </div>
      {identity?.user?.domain?.configuration?.pack?.error && (
        <div className="subAppBar error">
          <p
            dangerouslySetInnerHTML={{
              __html: identity?.user?.domain?.configuration?.pack?.error,
            }}
          />
        </div>
      )}
      {identity?.user?.domain &&
        identity?.user?.domain?.configuration?.pack?.renewalDate &&
        dayDiff < 45 && (
          <div className="subAppBar warning">
            <p>
              <strong>
                ⚠️ Votre abonnemement sera automatiquement renouvelé le{' '}
                <span className="underline">
                  {moment(renewalDate).format('DD/MM/YYYY')}
                </span>{' '}
                pour une durée de 1 an ({pricing}€HT).
              </strong>
            </p>
          </div>
        )}
    </>
  );
};

export default CustomAppBar;
