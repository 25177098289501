import React from 'react';
import { Pagination, useGetIdentity } from 'react-admin';

interface Props {}

const MyPagination = (props: Props) => {
  const { identity } = useGetIdentity();
  return (
    <Pagination
      rowsPerPageOptions={[
        ...(identity?.user?.isAdmin ? [10, 25, 50, 100] : [10, 25]),
      ]}
      {...props}
    />
  );
};

export default MyPagination;
