import {
  BooleanInput,
  ImageField,
  ImageInput,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import { getMaxUploadSize, validateUrl } from '../Validate';
import { formatImage } from '../ImageTab';

const ContentTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <>
      {identity?.user?.isAdmin && (
        <TextInput
          source="reference"
          label={translate('reference')}
          validate={[validateUrl(identity?.user?.isAdmin)]}
          helperText={false}
          fullWidth
        />
      )}

      <ImageInput
        source="image"
        format={formatImage}
        label={translate('imageMaxSize')}
        accept="image/*"
        maxSize={getMaxUploadSize()}
        placeholder={translate('imageInput')}
      >
        <ImageField label={translate('image')} />
      </ImageInput>

      {props?.record?.image && (
        <img
          src={props?.record?.image}
          alt=""
          style={{ maxHeight: 150, width: 'auto' }}
        />
      )}

      {props.data?.image && (
        <BooleanInput
          source="removeImage"
          label={translate('removeImage')}
          defaultValue={false}
          helperText={false}
        />
      )}
    </>
  );
};

export default ContentTab;
