import React from 'react';
import {
  useTranslate,
  BooleanInput,
  SelectInput,
  NumberInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { useWidgets } from '../../../hooks/useWidgets';
import { ReferenceInput } from 'react-admin';
import { pagePopulate } from '../../../populate';
import LocationInput from '../../LocationInput';

export const WidgetPages = ({
  source,
  record,
}: {
  source: any;
  record: any;
}) => {
  const translate = useTranslate();
  const { imageOrientationEnum, viewEnum, paginationPositionEnum } =
    useWidgets();
  return (
    <div className="panel-group">
      <BooleanInput
        source={source('hideTitle')}
        label={translate('widgets.hideTitle')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideDescription')}
        label={translate('widgets.hideDescription')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideCategory')}
        label={translate('widgets.hideCategory')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideLocation')}
        label={translate('widgets.hideLocation')}
        helperText={false}
      />
      <BooleanInput
        source={source('fullscreen')}
        label={translate('widgets.fullscreen')}
        helperText={false}
      />
      <SelectInput
        label={translate('configuration.view')}
        source={source('view')}
        choices={viewEnum}
        allowEmpty={true}
        initialValue={'list'}
        style={{ width: 250 }}
        helperText={false}
      />
      <SelectInput
        label={translate('configuration.imageOrientation')}
        source={source('imageOrientation')}
        choices={imageOrientationEnum}
        allowEmpty={true}
        initialValue={'square'}
        style={{ width: 250 }}
        helperText={false}
      />
      <NumberInput
        source={source('columns')}
        label={translate('widgets.columns')}
        initalValue={2}
        helperText={false}
      />
      <NumberInput
        source={source('limit')}
        label={translate('widgets.limit')}
        initialValue={5}
        helperText={false}
      />
      <p>
        <SelectInput
          label={translate('configuration.pagination.position.title')}
          source={source('pagination.position')}
          choices={paginationPositionEnum}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        />
      </p>
      <BooleanInput
        source={source('showPinned')}
        label={translate('widgets.showPinned')}
        initialValue={false}
        helperText={false}
      />
      <BooleanInput
        source={source('showFavorite')}
        label={translate('widgets.showFavorite')}
        initialValue={false}
        helperText={false}
      />
      <ReferenceInput
        source={source('folder')}
        reference="page"
        label={translate('parent')}
        filter={{
          folder: true,
          status: 'published',
          domain: record?.id,
          _populate: pagePopulate.join(','),
        }}
        perPage={50}
        allowEmpty={true}
        helperText={false}
      >
        <SelectInput optionText="title" helperText={false} />
      </ReferenceInput>
      <p>
        <NumberInput source={source('folder')} label={translate('folder')} />
      </p>
      <div className="panel-group">
        <p className="title">{translate('geometry.title')}</p>
        <LocationInput
          latitude={source('geometry.latitude')}
          longitude={source('geometry.longitude')}
          latitudeDelta={source('geometry.latitudeDelta')}
          longitudeDelta={source('geometry.longitudeDelta')}
        />
      </div>

      <JsonInput
        source={source('params')}
        label={translate('widgets.params')}
        helperText={false}
      />
    </div>
  );
};
