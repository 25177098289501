import React from 'react';
import { useTranslate, BooleanInput } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import TargetInput from '../TargetInput';

const RootTab = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <TargetInput />

      <BooleanInput
        source="configuration.hideTitle"
        label={translate('configuration.hideTitle')}
        defaultValue={false}
        helperText={false}
      />

      <BooleanInput
        source="configuration.hideDescription"
        label={translate('hideDescription')}
        helperText={false}
      />

      <BooleanInput
        source="configuration.hideCategory"
        label={translate('configuration.hideCategory')}
        defaultValue={false}
        helperText={false}
      />

      <JsonInput
        source="configuration.params"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="configuration"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="actions"
        reactJsonOptions={{
          name: null,
          collapsed: false,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
    </>
  );
};

export default RootTab;
