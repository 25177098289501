import React from 'react';
import { useTranslate, TextInput, NumberInput } from 'react-admin';

export const WidgetLoyalty = ({ source }: { source: any }) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <TextInput
        source={source('title')}
        label={translate('loyalty.title')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source={source('description')}
        label={translate('loyalty.description')}
        helperText={false}
        fullWidth
      />
      <NumberInput
        source={source('points')}
        label={translate('loyalty.points')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source={source('password')}
        label={translate('loyalty.password')}
        helperText={false}
        fullWidth
      />
    </div>
  );
};
