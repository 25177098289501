import React from 'react';
import { useTranslate, Filter, useGetIdentity } from 'react-admin';
import CategoryFilter from '../CategoryFilter';
import DomainFilter from '../DomainFilter';
import SearchFilter from '../SearchFilter';

const UserFilter = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <Filter {...props}>
      {identity?.user?.isAdmin && (
        <DomainFilter source="domain" label={translate('domain')} />
      )}

      <SearchFilter
        label={translate('search')}
        source="email_contains"
        {...props}
        alwaysOn
      />

      <CategoryFilter
        source="category"
        label={translate('category')}
        {...props}
      />
    </Filter>
  );
};

export default UserFilter;
