import React from 'react';
import {
  ReferenceField,
  useTranslate,
  List,
  Datagrid,
  TextField,
  NumberField,
} from 'react-admin';

import MyPagination from '../Pagination';
import DepartmentFilter from './DepartmentFilter';

const DepartmentList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      title={translate('departments')}
      filters={<DepartmentFilter />}
      pagination={<MyPagination />}
      perPage={10}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <NumberField source="id" label={translate('id')} />
        <NumberField source="uuid" label={translate('uuid')} />
        <TextField source="name" />
        <ReferenceField
          source="region"
          reference="region"
          label={translate('region')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="country"
          reference="country"
          label={translate('country')}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default DepartmentList;
