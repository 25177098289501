import React from 'react';
import {
  useTranslate,
  Show,
  TextField,
  SimpleShowLayout,
  ImageField,
  ReferenceField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

const FFFShow = (props: any) => {
  const translate = useTranslate();

  return (
    <Show title={translate('schools')} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="uuid" />
        <TextField source="name" />
        <ImageField source={'icon'} label={translate('icon')} />
        <TextField source="url" />
        <ReferenceField
          source="department"
          reference="department"
          label={translate('department')}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="city"
          reference="city"
          link="show"
          label={translate('city')}
        >
          <TextField source="name" />
        </ReferenceField>
        <JsonField source="configuration" />
        <JsonField source="geometry" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FFFShow;
