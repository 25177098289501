import { regex } from 'react-admin';

export function validateUrl(isAdmin: boolean) {
  if (isAdmin) return true;
  else
    return regex(
      // eslint-disable-next-line
      /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~%:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      `Le format du lien n'est pas correct`
    );
}

export function validateTime() {
  return regex(
    // eslint-disable-next-line
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    `Le format de l'heure n'est pas correct`
  );
}

export function getMaxUploadSize() {
  // 1000000 => 1MB
  const MAX_UPLOAD_SIZE_MB = 2;
  return MAX_UPLOAD_SIZE_MB * 1000000;
}
