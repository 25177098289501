import React from 'react';
import {
  useTranslate,
  Create,
  useGetIdentity,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { FormTab, TabbedForm } from 'ra-ui-materialui';
import MainTab from './MainTab';
import RootTab from './RootTab';
import AdressTab from '../AdressTab';
import DesignTab from './DesignTab';

const CreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable={true} />
  </Toolbar>
);

const NotificationCreate = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <Create title={translate('notifications')} {...props}>
      <TabbedForm redirect="list" toolbar={<CreateToolbar />}>
        <FormTab label={translate('infos')}>
          <MainTab mode="create" />
        </FormTab>

        {(identity?.user?.isAdmin ||
          identity?.user?.domain?.configuration?.enableMap) && (
          <FormTab label={translate('adress')}>
            <AdressTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};

export default NotificationCreate;
