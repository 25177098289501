import React from 'react';
import {
  useTranslate,
  Edit,
  useGetIdentity,
  useGetOne,
  TabbedForm,
  FormTab,
} from 'react-admin';
import EditToolbar from '../EditToolBar';
import ContentTab from '../ContentTab';
import ImageTab from '../ImageTab';
import MainTab from './MainTab';
import DatesTab from './DatesTab';
import RootTab from './RootTab';
import AdressTab from '../AdressTab';
import DesignTab from './DesignTab';

const EventEdit = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { data } = useGetOne('event', props.id || 0);

  return (
    <Edit title={translate('events')} {...props}>
      <TabbedForm
        redirect="list"
        toolbar={<EditToolbar hideDeletion={!identity?.user?.isAdmin} />}
      >
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" />
        </FormTab>

        <FormTab label={translate('dates')}>
          <DatesTab {...props} />
        </FormTab>

        <FormTab label={translate('image')}>
          <ImageTab image={data?.image} />
        </FormTab>

        <FormTab label={translate('content')}>
          <ContentTab
            enableOpenInBrower={
              identity?.user?.isAdmin ||
              identity?.user?.domain?.configuration?.enableOpenInBrowser
            }
          />
        </FormTab>

        {(identity?.user?.isAdmin ||
          identity?.user?.domain?.configuration?.enableMap) && (
          <FormTab label={translate('adress')}>
            <AdressTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default EventEdit;
