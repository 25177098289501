import React from 'react';
import ChipField from './ChipField';
import { useGetOne } from 'react-admin';

const CategoryField = ({ record, source }: any) => {
  return record && record.category ? (
    <CategoryChipsField record={record[source]?.id || record[source]} />
  ) : (
    <></>
  );
};

const CategoryChipsField = ({ record }: any) => {
  const { data } = useGetOne('category', record || 0);
  return data && data.name ? (
    <ChipField
      record={record}
      label={data?.name}
      backgroundColor="#FAFAFA"
      color="#000"
    />
  ) : (
    <></>
  );
};

export default CategoryField;
