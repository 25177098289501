import React from 'react';
import {
  useTranslate,
  BooleanInput,
  TextInput,
  SelectInput,
  NumberInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { useWidgets } from '../../../hooks/useWidgets';

export const WidgetArticles = ({ source }: { source: any }) => {
  const translate = useTranslate();
  const { imageOrientationEnum, viewEnum, paginationPositionEnum } =
    useWidgets();
  return (
    <div className="panel-group">
      <TextInput
        source={source('title')}
        label={translate('widgets.title')}
        helperText={false}
        fullWidth
      />
      <BooleanInput
        source={source('hideTitle')}
        label={translate('widgets.hideTitle')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideDescription')}
        label={translate('widgets.hideDescription')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideCategory')}
        label={translate('widgets.hideCategory')}
        helperText={false}
      />
      <BooleanInput
        source={source('hideLocation')}
        label={translate('widgets.hideLocation')}
        helperText={false}
      />
      <BooleanInput
        source={source('fullscreen')}
        label={translate('widgets.fullscreen')}
        helperText={false}
      />
      <SelectInput
        label={translate('configuration.view')}
        source={source('view')}
        choices={viewEnum}
        allowEmpty={true}
        initialValue={'list'}
        style={{ width: 250 }}
        helperText={false}
      />
      <SelectInput
        label={translate('configuration.imageOrientation')}
        source={source('imageOrientation')}
        choices={imageOrientationEnum}
        allowEmpty={true}
        initialValue={'square'}
        style={{ width: 250 }}
        helperText={false}
      />
      <NumberInput
        source={source('columns')}
        label={translate('widgets.columns')}
        helperText={false}
      />
      <NumberInput
        source={source('limit')}
        label={translate('widgets.limit')}
        helperText={false}
      />
      <p>
        <SelectInput
          label={translate('configuration.pagination.position.title')}
          source={source('pagination.position')}
          choices={paginationPositionEnum}
          allowEmpty={true}
          style={{ width: 250 }}
          helperText={false}
        />
      </p>
      <JsonInput
        source={source('params')}
        label={translate('widgets.params')}
        helperText={false}
      />
    </div>
  );
};
