import { ReferenceInput, SelectInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate } from 'react-admin';
import { categoryPopulate } from '../populate';
import { useGetIdentity } from 'react-admin';

interface Props {
  label: string;
  source: string;
  filterValues: any;
}

const CategoryFilter = (props: Props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <ReferenceInput
      source={props.source}
      reference="category"
      label={translate('category')}
      perPage={50}
      allowEmpty={true}
      sort={{ field: 'sort', order: 'ASC' }}
      filter={{
        status: 'published',
        ...(identity?.user.domain ? { domain: identity?.user.domain } : {}),
        ...(props.filterValues?.domain
          ? { domain: props.filterValues?.domain }
          : {}),
        _populate: categoryPopulate.join(','),
      }}
      helperText={false}
    >
      <SelectInput optionText="name" helperText={false} />
    </ReferenceInput>
  );
};

export default CategoryFilter;
