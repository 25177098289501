import React from 'react';
import { useTranslate, Button, useGetIdentity, useRedirect } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import PageIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Add';
import ArticleIcon from '@material-ui/icons/Add';
import NotificationIcon from '@material-ui/icons/AddAlert';

const CloneButton = (props: any) => {
  const { identity } = useGetIdentity();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  return (
    <>
      <CloneAsNotificationButton record={props.record} />
      {identity?.user?.isAdmin && !isXSmall && (
        <CloneAsEventButton record={props.record} />
      )}
      {identity?.user?.isAdmin && !isXSmall && (
        <CloneAsPageButton record={props.record} />
      )}
      {identity?.user?.isAdmin && !isXSmall && (
        <CloneAsArticleButton record={props.record} />
      )}
    </>
  );
};

type CloneAsButtonProps = {
  record: any;
  label: string;
  redirection: 'page' | 'event' | 'notification' | 'article';
  Icon: any;
};
const CloneAsButton = ({
  record,
  label,
  redirection,
  Icon,
}: CloneAsButtonProps) => {
  const redirect = useRedirect();
  const { identity } = useGetIdentity();

  const clone = async (event: any) => {
    event.stopPropagation();
    if (record && identity.jwt) {
      if (record.imageCDNUrl) {
        const remoteImageFile = await fetch(record.imageCDNUrl);
        let remoteImageBlob = await remoteImageFile.blob();
        record.image = await fileToBase64(remoteImageBlob);
      }

      redirect(
        'create',
        redirection,
        1,
        {},
        {
          record: {
            ...record,
            clone: true,
            //publish_at: new Date(),
            sent_date: null,
          },
        }
      );
    }
  };

  return (
    <>
      {record && record.domain && (
        <Button
          color="primary"
          onClick={clone}
          label={label}
          style={{ textTransform: 'none' }}
        >
          <Icon />
        </Button>
      )}
    </>
  );
};

const CloneAsPageButton = (props: any) => {
  const translate = useTranslate();

  return (
    <CloneAsButton
      record={props.record}
      label={translate('page')}
      redirection={'page'}
      Icon={PageIcon}
    />
  );
};

const CloneAsEventButton = (props: any) => {
  const translate = useTranslate();

  return (
    <CloneAsButton
      record={props.record}
      label={translate('event')}
      redirection={'event'}
      Icon={EventIcon}
    />
  );
};

const CloneAsNotificationButton = (props: any) => {
  const translate = useTranslate();

  return (
    <CloneAsButton
      record={props.record}
      label={translate('notification')}
      redirection={'notification'}
      Icon={NotificationIcon}
    />
  );
};

const CloneAsArticleButton = (props: any) => {
  const translate = useTranslate();

  return (
    <CloneAsButton
      record={props.record}
      label={translate('article')}
      redirection={'article'}
      Icon={ArticleIcon}
    />
  );
};

const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

export { CloneButton };
