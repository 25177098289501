import { TabbedForm } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, Create, FormTab, useGetIdentity } from 'react-admin';
import ContentTab from './ContentTab';
import MainTab from './MainTab';
import SectionTab from './SectionTab';
import DesignTab from './DesignTab';
import RootTab from './RootTab';

const ImageCreate = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <Create title={translate('images')} {...props}>
      <TabbedForm redirect="list">
        <FormTab label={translate('infos')}>
          <MainTab mode="create" />
        </FormTab>

        <FormTab label={translate('image')}>
          <ContentTab />
        </FormTab>

        <FormTab label={translate('sections')}>
          <SectionTab />
        </FormTab>

        {identity?.user?.isAdmin && (
          <FormTab label={translate('design')}>
            <DesignTab />
          </FormTab>
        )}

        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  );
};

export default ImageCreate;
