import { ReferenceInput, SelectInput } from 'ra-ui-materialui';
import React from 'react';
import { useTranslate, useGetIdentity, required } from 'react-admin';
import { categoryPopulate } from '../populate';

interface Props {
  domain?: number;
}
const CategoryInput = (props: Props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <div>
      <ReferenceInput
        source="category"
        reference="category"
        label={translate('category')}
        allowEmpty={identity?.user.category ? false : true}
        validate={identity?.user.category ? [required()] : []}
        perPage={50}
        sort={{ field: 'sort', order: 'ASC' }}
        filter={{
          status: 'published',
          ...(props.domain ? { domain: props.domain } : {}),
          _populate: categoryPopulate.join(','),
        }}
        style={{ width: 250 }}
        initialValue={identity?.user.category}
        helperText={false}
      >
        <SelectInput optionText="name" helperText={false} />
      </ReferenceInput>
    </div>
  );
};

export default CategoryInput;
