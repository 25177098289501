import { BooleanInput, SelectInput } from 'ra-ui-materialui';
import React, { useState } from 'react';
import { useTranslate, TextInput, useGetIdentity } from 'react-admin';
import { validateUrl } from './Validate';
import RichTextInput from 'ra-input-rich-text';
import ActionsTab from './ActionsTab';

interface Props {
  record?: any;
  enableOpenInBrower?: boolean;
  disableSharing?: boolean;
  disableFavorite?: boolean;
  disablePropulsed?: boolean;
}

const ContentTab = (props: Props) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const [isUrl, setIsUrl] = useState<boolean>(
    !!props?.record?.reference || false
  );
  const [hasReference, setHasReference] = useState<boolean>(
    !!props?.record?.reference || false
  );
  return (
    <>
      <div>
        <SelectInput
          source="isUrl"
          label={translate('isUrlHelper')}
          choices={[
            { id: false, name: 'Contenu libre' },
            { id: true, name: 'Site internet' },
          ]}
          allowEmpty={false}
          defaultValue={isUrl}
          style={{ maxWidth: 550 }}
          onChange={(e: any) => {
            const value = e.target.value;
            setIsUrl(value);
          }}
          fullWidth
          helperText={
            hasReference && !isUrl
              ? "! Si vous indiquez un lien internet, celui-ci sera toujours prioritaire dans l'application. Pensez à vider ce champ si vous ne souhaitez pas l'utiliser."
              : ''
          }
        />
      </div>

      {isUrl ? (
        <>
          <TextInput
            source="reference"
            label={translate('reference')}
            fullWidth
            validate={[validateUrl(identity?.user?.isAdmin)]}
            onChange={(e: any) => {
              setHasReference(!!e.target.value);
            }}
            helperText={false}
          />
          {props.enableOpenInBrower && (
            <BooleanInput
              source="openInBrowser"
              label={translate('openInBrowser')}
              helperText={false}
              fullWidth
            />
          )}
        </>
      ) : (
        <>
          <RichTextInput source="content" label={''} />
        </>
      )}

      <ActionsTab />
    </>
  );
};

export default ContentTab;
