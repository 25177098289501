import React from 'react';
import { SimpleFormIterator } from 'react-admin';
import { ArrayInput, useTranslate, TextInput } from 'react-admin';
import { validateTime } from '../Validate';

const HourForm = (props: any) => {
  const translate = useTranslate();
  return (
    <div className="panel-group">
      <p className="title">{props.label}</p>
      <ArrayInput source={props.source} label={false}>
        <SimpleFormIterator>
          <TextInput
            source="from"
            label={translate('hours.from')}
            helperText={'Format: 09:30'}
            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
            validate={[validateTime()]}
          />
          <TextInput
            source="to"
            label={translate('hours.to')}
            helperText={'Format: 17:30'}
            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
            validate={[validateTime()]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

const HoursTab = (props: any) => {
  const translate = useTranslate();

  return (
    <div>
      <HourForm source="hours.monday" label={translate('days.monday')} />
      <HourForm source="hours.tuesday" label={translate('days.tuesday')} />
      <HourForm source="hours.wednesday" label={translate('days.wednesday')} />
      <HourForm source="hours.thursday" label={translate('days.thursday')} />
      <HourForm source="hours.friday" label={translate('days.friday')} />
      <HourForm source="hours.saturday" label={translate('days.saturday')} />
      <HourForm source="hours.sunday" label={translate('days.sunday')} />
    </div>
  );
};

export default HoursTab;
